export default {

    menuItems: [
      {
        name: 'HOME',
        //url: '/?id=1111'

        //上記と同じことだがquery使用して、
        //url: {path: "/",query: {id: '1' }}

        //pathではなく$routeオブジェクト利用
        //url: { name: 'home', params: { id: 1 }}
        url: { name: 'home'}
      },

      {
        name: 'ネット予約',
        //query使用して、
        //url: {path: "/order",query: {id: '2' }}

        //pathではなく$routeオブジェクト利用
        //url: { name: 'order', params: { id: 2 }}
        url: {name: 'order'}
      },

      {
        name: 'お問合せ',
        //query使用して、
        //url: {path: "/toiawase",query: {id: '3' }}

        //pathではなく$routeオブジェクト利用
        url: { name: 'toiawase'}
      },
      {
        name: 'SITEMAP',
        //url: {path: "/site",query: {id: '4' }}
        //pathではなく$routeオブジェクト利用
        url: { name: 'site'}
      },

    ],

    tiryouItems: [
      {
        name: '矯正歯科',
        url: { name: 'kyousei', params: { id: '1' }}, //vuejs-pagenate対応時
        //url: { name: 'kyousei'},
        icon: 'mdi-toothbrush-paste'
      },
      {
        name: 'ホワイトニング',
        url: { name: 'whitening', params: { id: '1' }}, //vuejs-pagenate対応時
        //url: { name: 'whitening'},
        icon: 'mdi-toothbrush'
      },
      {
        name: 'インプラント',
        url: { name: 'inplant', params: { id: '1' }}, //vuejs-pagenate対応時
        //url: { name: 'inplant'},
        icon: 'mdi-tooth'
      },    
      {
        name: '歯周病',
        url: { name: 'sisyu', params: { id: '1' }}, //vuejs-pagenate対応時
        //url: { name: 'sisyu'},
        icon: 'mdi-emoticon-devil'
      },    
      {
        name: '審美歯科',
        //url: { name: 'sinbi'},
        url: { name: 'sinbi', params: { id: '1' }}, //vuejs-pagenate対応時
        icon: 'mdi-toothbrush-electric'
      },   
      {
        name: '入れ歯',
        //url: { name: 'sinbi'},
        url: { name: 'ireba', params: { id: '1' }}, //vuejs-pagenate対応時
        icon: 'mdi-tooth-outline'
      }, 
      {
        name: '治療例',
        //url: { name: 'tiryourei'},
        url: { name: 'tiryourei', params: { id: '1' }}, //vuejs-pagenate対応時
        icon: 'mdi-emoticon-happy-outline'
      },    
    ],        
    

    touinItems: [
      {
        name: '医院紹介',
        //url: {path: "/iin",query: {id: '10' }},
        //pathではなく$routeオブジェクト利用
        url: { name: 'iin'},
        icon: 'mdi-hospital-box-outline'
      },
      {
        name: '院長紹介',
        //url: {path: "/intyou",query: {id: '11' }},
        //pathではなく$routeオブジェクト利用

        //url: { name: 'intyou', params: { id: '1' }}, //vuejs-pagenate対応時
        url: { name: 'intyou'},
        icon: 'mdi-face-recognition'
      },    
    ],        

    //idは初期値では無しにしておきtiryoureiからのリンク時に指定する
    tiryousyousaiItems: [
      {
        name: '治療詳細',
        url: { name: 'tiryousyousai', params: { id: '13' }},
      },
  
    ],   


    h1title: [
      {
        id:  '1',
        name: 'home',
        title: '伊丹市の歯医者 石岡歯科医院'
      },
      {
        id: '2',
        name: 'order',
        title: '石岡歯科医院　ネット予約'
      },
      {
        id: '3',
        name: 'toiawase',
        title: '石岡歯科医院　お問合せ'
      },
      {
        id: '4',
        name: 'site',
        title: '石岡歯科医院　サイトマップ'
      },
      {
        id: '5',
        name: 'kyousei',
        title: '伊丹市の歯医者 矯正治療'
      },
      {
      id: '6',
      name: 'whitening',
        title: '伊丹市の歯医者 ホワイトニング'
      },
      {
      id: '7',
      name: 'inplant',
          title: '伊丹市の歯医者 インプラント治療'
      },
      {
      id: '8',
      name: 'sisyu',
          title: '伊丹市の歯医者 歯周病治療'
      },      
      {
      id: '9',
      name: 'sinbi',
          title: '伊丹市の歯医者 審美歯科治療'
      },      
      {
      id: '10',
      name: 'iin',
          title: '伊丹市の歯医者 石岡歯科医院紹介'
      },      
      {
      id: '11',
      name: 'intyou',
          title: '伊丹市の歯医者 石岡歯科院長紹介'
      },      
      {
      id: '12',
      name: 'tiryourei',
          title: '伊丹市の歯医者 石岡歯科医院の治療例'
      },
      {
        id: '13',
        name: 'tiryousyousai',
            title: '伊丹市の歯医者 石岡歯科医院の治療詳細'
      },
      {
        id: '14',
        name: 'ireba',
            title: '伊丹市の歯医者 入れ歯治療'
      },

    ],

    topheroItems: [
      //石岡歯科外観写真拡大
      {
        //src: require('@/assets2/20210823_114632.jpg'),
        src: require('@/assets2/gaikan01.jpg'),
      },
      //石岡歯科顕微鏡写真
      {
        src: require('@/assets2/kenbi01.jpg'),
      },
      //石岡歯科CT写真
      {
        src: require('@/assets2/ct01.jpg'),
      },
      /*子供写真
      {
        src: require('@/assets2/2262746_m.jpg'),
      },
      */
      /*医療器具写真
      {
        src: require('@/assets2/3987063_m.jpg'),
      },
      */
      /* 拡大石岡歯科写真
      {
        src: require('@/assets2/20210730_122420.jpg'),
      },
      */
     /*
      {
        src: require('@/assets2/3986960_s.jpg'),
      },
      */
     //消毒写真
      {
        src: require('@/assets2/3986575_s.jpg'),
      },

    ]
 
  
  }