import { render, staticRenderFns } from "./HomeAbout.vue?vue&type=template&id=b427e312&scoped=true&"
import script from "./HomeAbout.vue?vue&type=script&lang=js&"
export * from "./HomeAbout.vue?vue&type=script&lang=js&"
import style0 from "./HomeAbout.vue?vue&type=style&index=0&id=b427e312&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b427e312",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VCard,VCardActions,VCardSubtitle,VCardText,VCardTitle,VContainer,VImg,VRow,VSlideGroup,VSlideItem})
