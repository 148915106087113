<!--  
(注意)
1.bootstrap css指定
2.rooter.js constants.jsにて:id指定
3.main.js指定
-->
<!-- REP20220406 -->
<template>

  <section class="tiryourei">
    <AppBackgroundHolder :title="title"/>
  
    <v-container>
      <!-- パンくず -->
      <div>
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      </div>



      <h2 
      id="1"
      style="
        margin-bottom: 1rem;
        padding: 0.3em;
        background: #4DD0E1;
        border-left: solid 10px #0097A7;
        border-radius: 10px;
      "
      class="white--text text-md-h3 text-h4 font-weight-black"
      >治療例</h2>

      <!-- test logic -->
      <!-- <span>{{ scrollY }}</span> -->

<!--kokokara -------------------------------------------------------->

      <v-row class="home-ryoukin__contents">    
        <!--  
        <v-col cols="12" md="3"  v-for="(newsItem, index) in 30"
      :key="index"> -->
      <!-- md="3"でLAPTOP以上は横4(= 12/3)列ずつ出す -->
        <!-- <v-col cols="12" md="3"  v-for="list in displayLists" :key="list.id"> -->
        <v-col cols="12" md="3"  v-for="list in displayLists" :key="list.id">
          <v-card
            shaped
          >
          <!--(参考)
           <v-img :src="require('@/assets/sample.jpg')"></v-img> <<@ or .. でrequire要!

           :src= "'../assets2/' + list.gazou1_b" <<テキストと変数合成
          -->
            <v-img
              class="white--text align-end"
              :src="require('@/assets2/' + list.gazou1_b)"
              position="bottom 40% right 50%"
              alt="治療例の画像"
            >
              <v-card-title>{{list.name}}</v-card-title>
            </v-img>

            <v-card-subtitle class="pb-0">
              {{list.date}}
            </v-card-subtitle>

            <v-card-text class="text--primary">
              <div 
                  class="d-inline-block text-truncate"
                  style="max-width: 240px;"
              >{{list.naiyou}}</div>
            </v-card-text>
            <v-card-actions>
             <!-- <AppButton :button-text="バカチョンボタン" :url="url + (list.id) "/> -->
             <AppButton :button-text="buttonText" :url="{name: 'tiryousyousai', params: { id: list.id }}"/> 
             <!-- <router-link v-bind:to="{ name: 'tiryousyousai' , params:{ id: list.id }}">治療詳細</router-link> -->
            </v-card-actions>
          </v-card>
        </v-col>

      </v-row>  

      <!-- v-pagination
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="length"
          @input = "pageChange"
        ></v-pagination>
      </div>
      -->


         <!-- vuejs-pagenate  -->
        <!-- <link rel="stylesheet" href="https://cdn.bootcss.com/bootstrap/3.3.5/css/bootstrap.css">-->
        <div id="app" class="container">
          <!--
          <a href="">test</a>
          <h2>page {{currentPage}}</h2>
          -->

          <paginate
            v-model="currentPage"
            :page-count="getPageCount"
            :initial-page="4"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :prev-text="'＜'"
            :next-text="'＞'"
            :container-class="'pagination'"
            :page-class="'page-item'">
          </paginate>
        </div>
        <!-- vuejs-pagenate  -->


<!-- kokomade -------------------------------------------------->




<!--
      <h3
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >Title</h3>

      <h3
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >Hizuke</h3>

      <h3
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >Naiyou</h3>


      <v-sheet
        elevation="3"
        height="550"
        width="500"
        color="grey lighten-3"
        
        :rounded="rounded"
        class="vsheet__toc"
      >

-->
<!--
          <v-row class="d-flex child-flex">      
            <v-col cols="12" md="6"  v-for="(newsItem, index) in 6"
          :key="index"> 
          -->
          <!--md="2"でLAPTOP以上は横6(= 2/12)画面ずつ出す -->
        <!--
                <v-img
                  class="white--text align-end grey lighten-2"
                  src= '../assets2/D202108_3.jpg'
                 
                  
                  max-width="200"
                  max-height="150"
           
                  contain
               
                >
               
                </v-img>

            </v-col>

          </v-row>  



      </v-sheet>      

       <AppButton :button-text="buttonText" :url="url"/>
      -->


<!--
      <template>
        <v-list>
          <v-list-item v-for="list in displayLists" :key="list.index">
            <v-list-item-content>
              <v-list-item-title>{{ list.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="length"
            @input = "pageChange"
          ></v-pagination>
        </div>
      </template>
-->

<!--
      <template>
        <div class="count">  
          
          <table cellspacing="0" border="1px">  
            <tr>  
              <th>MYSQLテスト id</th>  
              <th>date</th>  
              <th>title</th>  
              <th>naiyou</th>  
            </tr>  
            <tr v-for="user in sinryou"
            :key="user.id"
            >  
              <td>{{user.id}}</td> 
              <td>{{user.date}}</td>  
              <td>{{user.title}}</td>  
              <td>{{user.naiyou}}</td>  
            
            </tr>  
          </table>  

        </div>  

      </template>

-->
    

    </v-container>

<go-top
  max-width="50"
  bg-color="#0097A7"
  :size="50"
></go-top>

  </section>

</template>


<script>
import AppBackgroundHolder from './AppBackgroundHolder.vue'
import GoTop from '@inotom/vue-go-top'

import axios from 'axios'
import AppButton from './AppButton.vue'
//import constants from '../common/constants'

import Paginate from 'vuejs-paginate'
//Vue.component('paginate', Paginate)

 
export default {
  data () {
    return {
      page: 1,
      length:0,
      lists: [],
      displayLists: [],

      pageSize: 4,      //1ページに表示するデータ数 vuejs-pagenate

      sinryou: [],

      title: '治療例',

      buttonText: '治療詳細へ',
      url: '/tiryousyousai/', // <<<変更しろ！！！
      //tiryouRei: constants.tiryouRei,
      //tiryouRei: [],
    
     

      //パンくず
      items: [
        {
          text: 'Home',
          disabled: false,
          href: '../',
        },
        {
          text: '治療例',
          disabled: true,
          href: './tiryourei',
        },
      ],  
      
      //(REP D20220328) parPage: 10,
      parPage: 4,
      currentPage: this.currentPage = this.$route.params.id || 1,

      //currentPage: 1,

      /*
      //コンポーネントだけが変わるとき
      beforeRoutEnter :function(to, from, next) {
        alert('beforeEnter');
        next({ 
        name: 'tiryourei', 
        params: { 
          id: 5
        }}) // Hookを解決する = 次の遷移を行う
      },

      //ルート情報の変化を検知するとき
      beforeRouteUpdate :function(to, from, next) {
        alert('beforeUpdate');
        next({ 
        name: 'tiryourei', 
        params: { 
          //id: this.currentPage 
          id : 5
        }}) // Hookを解決する = 次の遷移を行う
      },
      */

     /* この位置悪いんか？？効かない
      beforeRouteEnter (to, form, next) {
        console.log('component:beforeRouteEnter');
        console.log('---------');
        next();
      },
      beforeRouteUpdate (to, form, next) {
        console.log('component:beforeRouteUpdate');
        //this.updateItem();
        next();
      },
      beforeRouteLeave (to, form, next) {
        console.log('component:beforeRouteLeave');
        next();
      }      
      */

     //test logic
     scrollY: 0,


    }
  },

  //  コンポーネントに指定時や！/////////
  beforeRouteEnter : function (to, from, next) {
    //console.log('component:beforeRouteEnter');
    //console.log(this.$route.fullPath);
    //console.log(to.$route.fullPath);
    //console.log(from.$route.fullPath);
    //this.updateItem();
    next();
  },
  beforeRouteUpdate : function (to, from, next) {
    //console.log('component:beforeRouteUpdate');
    //this.updateItem();
    next();
  },
  //////////////////////////////////////
  

  watch: {
    '$route'(to,) {
      this.currentPage = to.params.id;
      //alert(to.params.id);
      //alert(from.params.id);
      //next();
    },

    //戻り位置対応gita test logic
    /*
    hogeList: function() {
        this.$nextTick(() => {
            var positionY = sessionStorage.getItem('positionY')
            scrollTo(0, positionY);
            setTimeout(function(){
                scrollTo(0, positionY);
            }, 50000);
        })
    },
    */
    
  },


  methods: {
    /* v-pagination
    pageChange: function(pageNumber){
      this.displayLists = this.sinryou.slice(this.pageSize*(pageNumber -1), this.pageSize*(pageNumber));
    },
    */

    clickCallback: function () {
      this.displayLists = this.sinryou.slice(this.pageSize*(this.currentPage -1), this.pageSize*(this.currentPage));
      //alert(location.href);
      this.$router.push({ 
        name: 'tiryourei', 
        params: { 
          id: this.currentPage 
        }
      });   

      //test logic  DEL20220406
      //location.reload(true);
      
      //alert(location.href);
      //alert(location.hash);
    },

    //hash logic
    parseHash: function() { // ハッシュからパラメータを取り出す
        //LOGOC変えたら一旦ブラウザ閉じてlocalhostあけ直してテスト！
        //const hash = location.hash;
        //const hash = this.$route.hash; //✖出ない
        //alert(hash);
        //location.replace('https://www.ishiokashika.net/index9.html#/tiryourei/2'); //変わったが△(一覧出ずいきなりこれにU移動！↑のalert出ないが)
        //location.reload(true);
        //location.href = hash ;
        //alert(location.href);
        //location.href = 'https://www.ishiokashika.net/index9.html'+ '#/tiryourei/3' ; //変わった〇(alertの後で)
        //location.href = 'http://localhost:8080/#/intyou/4'; //location.reload()下に入れたら無限にalertでる,alert消したら画面白い！✖
        //location.reload();


        this.updateItem();

        // これに変えてrouter.go()リロードにしてもダメ！
        this.$router.push({ 
          name: 'tiryourei', 
          params: { 
          id: this.currentPage
          }
        });


        //戻り位置対応gita test logic
        /*
        this.$nextTick(() => {
            var positionY = sessionStorage.getItem('positionY')
            scrollTo(0, positionY);
            setTimeout(function(){
                scrollTo(0, positionY);
            }, 50000);
        }); 
        */
    
  


        //this.updateItem(); //これをthis.$router.pushの後に入れても同じ結果でした。
        

        /*
        //1回だけリロードしないと↑のrouter.pushに飛ばないため
        if (window.name != "any") {
          alert("1回だけリロードします");
          location.reload();
          window.name = "any";
        } else {
          window.name = "";
        }
        */
        

       /*
       //routerにてリロード処理する方法(これも1回だけ) ダメ！！
       if (window.name != "any") {
          //this.$router.go({path: this.$router.currentRoute.path, force: true})
          //', force: true})
          /* コレハダメ！pathシカあかん！
          this.$router.go({ 
          name: 'tiryourei', 
          params: { 
          id: '5'
          },force:true})
          */
         /*
          window.name = "any";
        } else {
          window.name = "";
        }
        */

       /* 無限ループ読み込みダメ！
       this.$router.go({ 
          name: 'tiryourei', 
          params: { 
          id: '5'
          },force:true})
          */
      
    },

    updateItem: function(){
        axios.get('/functions/db_tiryourei.php').then((response) => {    //d20220406
              // console.log(data); // works fine
              this.sinryou = response.data
              //this.tiryouRei = this.sinryou
              console.log('success')
              console.log(response.data)

              //axiousから取るときはココで以下すること！mountedでしたら取れない！
              this.length = Math.ceil(this.sinryou.length/this.pageSize);
              //this.displayLists = this.sinryou.slice(this.pageSize*(this.page -1), this.pageSize*(this.page));

              this.displayLists = this.sinryou.slice(this.pageSize*(this.currentPage -1), this.pageSize*(this.currentPage));
              //this.displayLists = this.sinryou.slice(this.pageSize*(5 -1), this.pageSize*(5));

              //console.log('length  '+this.length)
              //console.log('displayLists  ' + this.displayLists)
        }
      );
    },

    //test logic
    handleScroll() {
        this.scrollY = window.scrollY;
    },

  },

  computed: {
    getItems: function() {
      return this.sinryou;
    },
    getLists: function(){
      let current = this.currentPage * this.parPage;
      let start = current - this.parPage;
      return this.getItems.slice(start, current);
    },
    getPageCount: function() {
      return Math.ceil(this.getItems.length / this.parPage);
    }
   },

 
 

  created() {

    //ブラウザバック対応 main.jsも修正してる
    /* paginationはダメだった！！！
    if (this.$browserBackFlg) {
      // ブラウザバック時の処理
      alert('ブラウザバック！');
    }
    else {
      // ブラウザバック時でなく普通のページ遷移のときの処理
      alert('普通！');
    }
    */



      axios.get('/functions/db_tiryourei.php').then((response) => {    //d20220406
      // console.log(data); // works fine
      this.sinryou = response.data
      //this.tiryouRei = this.sinryou
      //console.log('success')
      //console.log(response.data)

      //axiousから取るときはココで以下すること！mountedでしたら取れない！
      this.length = Math.ceil(this.sinryou.length/this.pageSize);
      this.displayLists = this.sinryou.slice(this.pageSize*(this.page -1), this.pageSize*(this.page));
      //console.log('length  '+this.length)
      //console.log('displayLists  ' + this.displayLists)


      }
    );
  },


  components: {
    AppBackgroundHolder,
    GoTop,
    AppButton,
    Paginate,
  },

  mounted: function(){
		document.title = "伊丹市の歯医者 石岡歯科医院の治療例";
		document.querySelector("meta[name='description']").setAttribute('content', "治療例 - 伊丹市の歯医者石岡歯科医院");

    //戻り位置対応()from aka-shin.com////test logic
    /*
    (() => {
      let entries = performance.getEntriesByType("navigation");
      entries.forEach((entry) => {
        if (entry.type == "back_forward" && sessionStorage.getItem("scrollY") != null) {
          window.scrollTo(0, sessionStorage.getItem("scrollY"));
        }
      });
    })();
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("scrollY", window.scrollY);
    });
  */
    /////////////////

/* createdへ移動
    this.length = Math.ceil(this.sinryou.length/this.pageSize);
    this.displayLists = this.sinryou.slice(this.pageSize*(this.page -1), this.pageSize*(this.page));
    console.log('length  '+this.length)
    console.log('displayLists  ' + this.displayLists)
*/


/* lists配列自作するときはmountedで以下指定する
    this.lists = new Array(99).fill().map((v,i)=> {
      return { id : i,title : 'Title' + i };
    });
    this.length = Math.ceil(this.lists.length/this.pageSize);
    this.displayLists = this.lists.slice(this.pageSize*(this.page -1), this.pageSize*(this.page));
*/

    //hash logoc  DEL20220406
    //window.addEventListener('hashchange', this.parseHash); //ハッシュ値が変更になったときに発生するイベント ↓↑逆意味か？
    //this.parseHash();                                      //再読み込み、ページ戻りにの為に直接指定する

    //test logic  DEL20220406
    //window.addEventListener('scroll', this.handleScroll);

	},

  head: {
      // link tags
      link: [
        { rel: 'canonical', href: 'https://www.ishiokashika.net/tiryourei/1/' },   //d20220523
      ],
  },  

}
</script>
<style lang="scss" scoped>

.vsheet__toc {
  color:#616161; //textはgray-darken2
  padding: 20px 20px 20px 20px;
  margin-bottom: 20px;
  
  p {font-size: 24px;text-align: center;}

  a {text-decoration: none !important;color: #616161; }
  a:hover {text-decoration: underline !important;}
  li {padding-top:10px; list-style-type: none !important;}

}


</style>

<style lang="css">
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 3;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

</style>