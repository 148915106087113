<template>
  <section class="home-order">

    <v-container>

    <h2 
    style="
      margin-bottom: 1rem;
      padding: 0.3em;
      background: #4DD0E1;
      border-left: solid 10px #0097A7;
      border-radius: 10px;
    "
    class="white--text text-md-h3 text-h4 font-weight-black"
    >24時間予約システム(スマホからもOK！)</h2>

      <v-row>
        <v-col>
          <h3
          style="
            border-left: solid 10px  #0097A7;
            border-radius: 10px;
          "
          class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
          >予約システムでいつでもどこでも診療仮予約ができ便利です</h3>

           <v-img
                  :src="require('@/assets2/yoyaku.png')"
                  alt="予約システム"
                  max-width="431"
                  max-height="288"
                ></v-img>

          <p>
            当院では患者様の利便性向上の為、予約システムを導入しています。下記から簡単に予約できますので是非ご活用下さい（尚、折り返し連絡がつき次第予約成立とさせて頂きます）
          </p>
    
        </v-col>
      </v-row>
      <!-- <AppButton :button-text="buttonText" :url="url"/> -->
      <AppButton :button-text="buttonText" :url="{name: 'order'}"/> 

      <v-row>
        <v-col>
          <h3
          style="
            border-left: solid 10px  #0097A7;
            border-radius: 10px;
          "
          class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
          >地域密着型の歯科医院を目指して</h3>

          <p>
            石岡歯科医院では伊丹市はもちろんの事、その周辺の川西市、池田市、宝塚市、豊中市、西宮市、尼崎市、神戸市、大阪、遠くは東京からわざわざ通院の為に足を運んでくださる患者様もおられます。（駐車場もあります）
          </p>
    
        </v-col>
      </v-row>

    </v-container>


  </section>
</template>
<script>
 
import AppButton from './AppButton.vue'
 
export default {
  data () {
    return {
      buttonText: '予約システム',
      url: '/order/2',

      
    }
  },
  components: {
    AppButton
  }
}
</script>
<style lang="scss" scoped>
.home-order {
  margin-top: 20px;
  justify-content: center;
  color:#616161;

  
}

</style>