<template>

  <section class="iin">
    <AppBackgroundHolder :title="title"/>
  
    <v-container>
      <!-- パンくず -->
      <div>
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      </div>


      <h2 
      id="1"
      style="
        margin-bottom: 1rem;
        padding: 0.3em;
        background: #4DD0E1;
        border-left: solid 10px #0097A7;
        border-radius: 10px;
      "
      class="white--text text-md-h3 text-h4 font-weight-black"
      >石岡歯科医院紹介</h2>
      <h3
      id="1-1"
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >所在地・連絡先</h3>

      <template>
        <v-container class="grey lighten-5">
          <v-row no-gutters>

            <v-col
              cols="12"
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >

                <v-card-text class="text--primary">
                  <ul>
                    <li>住所
                      <ul>
                        <li>兵庫県伊丹市高台1丁目152-19（白ゆり幼稚園前）1Fに駐車場有り</li>
                      </ul>          
                    </li>
                    <li>連絡先
                      <ul>
                        <li>（TEL）072-770-0643</li>
                        <li> (E-MAIL)info@ishiokashika.net</li>
                      </ul>          
                    </li>
                    <li>診療時間
                      <ul>
                        <li>午前の部 AM 9:00～PM 1:00</li>
                        <li>午後の部 PM 3:00～PM 7:00</li>
                        <li>土曜日17時30分迄</li>
                        <li>新患・急患・随時受付、各種保険取扱</li>
                      </ul>          
                    </li>
                    <li>休診日
                      <ul>
                        <li>木曜日・日曜日・祝日</li>
                       
                      </ul>          
                    </li>
                    <li>診療科目
                      <ul>
                        <li>一般・矯正・小児歯科</li>
                        <li>（歯周病・インプラント・審美）</li>
                      </ul>          
                    </li>

                  </ul>
                </v-card-text>
              </v-card>
            </v-col>  

          <!--
            <v-col
              cols="12"
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
 
                <v-img
                  class="white--text align-end"
                  :src="require('@/assets2/map_d200605.gif')"
                  alt="地図"
                  height="auto"
                  width="auto"
                >
                </v-img>
                <v-card-subtitle class="pb-0">
                  地図
                </v-card-subtitle>

                <v-card-text class="text--primary">
                  <div>（当医院は白ゆり幼稚園の目の前です）</div>
                </v-card-text>
              </v-card>
            </v-col>  
            -->

          </v-row>
        </v-container>
      </template>

      <h3
      id="1-1"
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >地図</h3>

      <template>
        <v-container class="grey lighten-5">
          <v-row no-gutters>

            <v-col

            >
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52425.61035357728!2d135.37729024887088!3d34.790827547333905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000f0fdf4e371e3%3A0x73fb185cbf9afe7b!2z55-z5bKh5q2v56eR!5e0!3m2!1sja!2sjp!4v1634648729483!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>

            </v-col>  

          </v-row>
        </v-container>
      </template>

      <h3
      id="1-1"
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >医院内紹介</h3>

      <template>
        <v-container class="grey lighten-5">
          <p>当医院では充実した設備を備えており、患者様は安心して治療を受けて頂く事ができます。</p>
          <v-row no-gutters>
            <v-col

            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
                <v-img
                  class="grey--text text--darken-2 align-end"
                  :src="require('@/assets2/uketuke.jpg')"
                  alt="受付け窓口"
                  height="150"
                  width="200"
                >
                </v-img>
                <v-card-subtitle class="pb-0">
                  受付け窓口
                </v-card-subtitle>

              </v-card>
            </v-col> 
            <v-col

            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
                <v-img
                  class="grey--text text--darken-2 align-end"
                  :src="require('@/assets2/matiaisitu.jpg')"
                  alt="待合室"
                  height="150"
                  width="200"
                >
                </v-img>
                <v-card-subtitle class="pb-0">
                  待合室
                </v-card-subtitle>

              </v-card>
            </v-col> 
            <v-col

            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
                <v-img
                  class="grey--text text--darken-2 align-end"
                  :src="require('@/assets2/gaikan.jpg')"
                  alt="外観"
                  height="150"
                  width="200"
                >
                </v-img>
                <v-card-subtitle class="pb-0">
                  外観
                </v-card-subtitle>

              </v-card>
            </v-col> 
            <v-col

            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
                <v-img
                  class="grey--text text--darken-2 align-end"
                  :src="require('@/assets2/sinsatusitu.jpg')"
                  alt="診察室"
                  height="150"
                  width="200"
                >
                </v-img>
                <v-card-subtitle class="pb-0">
                  診察室
                </v-card-subtitle>

              </v-card>
            </v-col> 

          </v-row>
        </v-container>
      </template>

      <h3
      id="1-1"
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >スタッフ紹介</h3>

      <template>
        <v-container class="grey lighten-5">
          <p>皆、やさしい明るいスタッフです。</p>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
                <v-img
                  class="grey--text text--darken-2 align-end"
                  :src="require('@/assets2/20210823_123711.jpg')"
                  alt="スタッフ"

                >
                </v-img>
                

              </v-card>
            </v-col> 
          </v-row>
        </v-container>
      </template>


      <h3
      id="1-1"
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >匠の技術</h3>

      <template>
        <v-container class="grey lighten-5">
          <v-row no-gutters>

           <v-col
              cols="12"
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
 
                <v-img
                  class="white--text align-end"
                  :src="require('@/assets2/takumi.jpg')"
                  alt="匠の技術"
                  height="147"
                  width="220"
                >
                </v-img>
                <v-card-subtitle class="pb-0">
                  匠の技術
                </v-card-subtitle>

                <v-card-text class="text--primary">
                  <div>当院の技工物（かぶせ物、入れ歯等）は日本でトップレベルの技工所の当院指定の匠によって当院で精密に作られた型を用いて作られています。

技工所によっては流れ作業で仕事をしていたり、労働力の安いアジアで製作している所もあるようですが心配なので当院では使用しておりません。

技工士さんはプロですのでＤｒ選びには厳しいですが、「この医院なら」と技工士さんからの紹介も頂いており感謝しております。</div>
                </v-card-text>
              </v-card>
            </v-col>            
          </v-row>
        </v-container>
      </template>      

      <h3
      id="1-1"
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >ＣＴを導入しています</h3>

      <template>
        <v-container class="grey lighten-5">
          <p>この度ＣＴを導入し、より高精度の診断を行う事が可能となりました。インプラント等の手術の時にも非常に役に立つと思います。</p>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
                <v-img
                  class="grey--text text--darken-2 align-end"
                  :src="require('@/assets2/2012-12-03 13.50.jpg')"
                  alt="CT画像１"
                  height="165"
                  width="220"
                >
                </v-img>
                

              </v-card>
            </v-col> 
            <v-col
              cols="12"
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
                <v-img
                  class="grey--text text--darken-2 align-end"
                  :src="require('@/assets2/VR.jpg')"
                  alt="CT画像2"
                  height="165"
                  width="220"
                >
                </v-img>

              </v-card>
            </v-col> 

          </v-row>
        </v-container>
      </template>


      <h3
      id="1-1"
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >日本の歯科100選</h3>

      <template>
        <v-container class="grey lighten-5">
          <p>この度日本の歯科100選に掲載されました。</p>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              > 
                <v-img
                  class="grey--text text--darken-2 align-end"
                  :src="require('@/assets2/D20151225_1.gif')"
                  alt="日本の歯科100選"
                  height="400"
                  width="225"
                >
                </v-img>
                

              </v-card>
            </v-col> 
            <v-col
              cols="12"
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
                <v-img
                  class="grey--text text--darken-2 align-end"
                  :src="require('@/assets2/D20151225_2.jpg')"
                  alt="日本の歯科100選2"
                  height="400"
                  width="225"
                >
                </v-img>

              </v-card>
            </v-col> 

          </v-row>
        </v-container>
      </template>

      <h3
      id="1-1"
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >マイクロスコープを導入しました</h3>

      <template>
        <v-container class="grey lighten-5">
          <p>より細かいところが見えるようになり精密な治療に役立ちます。</p>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
                <v-img
                  class="grey--text text--darken-2 align-end"
                  :src="require('@/assets2/D201910_micro.jpg')"
                  alt="日本の歯科100選"
                  height="504"
                  width="378"
                >
                </v-img>
                

              </v-card>
            </v-col> 
          </v-row>
        </v-container>
      </template>


      


    </v-container>

<go-top
  max-width="50"
  bg-color="#0097A7"
  :size="50"
></go-top>

  </section>

</template>
<script>
import AppBackgroundHolder from './AppBackgroundHolder.vue'
import GoTop from '@inotom/vue-go-top'

 
export default {
  data () {
    return {

      title: '医院紹介',

      //パンくず
      items: [
        {
          text: 'Home',
          disabled: false,
          //href: '/',
          to: '/'
        },
        {
          text: '医院紹介',
          disabled: true,
          //href: './iin',
          to: '/iin/'
        },    
      ],      
    }
  },



  components: {
    AppBackgroundHolder,
    GoTop,
  },


    //  コンポーネントに指定時や！/////////
  beforeRouteEnter : function (to, from, next) {
    console.log('component:beforeRouteEnter');
    //console.log(this.$route.fullPath);
    //console.log(to.$route.fullPath);
    //console.log(from.params.id);
    console.log(from.fullPath);
    console.log(from.path);
    console.log(from.name);
    console.log(from.params.id);
    //this.updateItem();
    next();
  },
  beforeRouteUpdate : function (to, from, next) {
    console.log('component:beforeRouteUpdate');
    //this.updateItem();
    next();
  },
  //////////////////////////////////////  

  mounted: function(){
		document.title = "伊丹市の歯医者 石岡歯科医院紹介";
		document.querySelector("meta[name='description']").setAttribute('content', "医院紹介 - 伊丹市の歯医者石岡歯科医院");
    // ADD20220406 ブラウザバックを無効化に伴い当URLは有効化するため
    //history.pushState({}, '', location.href);
	},

  head: {
      // link tags
      link: [
        { rel: 'canonical', href: 'https://www.ishiokashika.net/iin/' }, //REP20220523
      ],
  },  

}
</script>
<style lang="scss" scoped>


</style>