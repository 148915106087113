<template>
  <section class="home-about">
   
    <h2 style="color:#FF6666 ;" class="text-md-h3 text-h4 font-weight-black text-center">患者様個々ニーズに応じた治療方針</h2>

    <v-container>
      <v-row>
       
        <p class="text-justify">
          患者様一人一人の歯の形、ニーズも全く異なります。当医院では患者様個々のニーズに応じたキメ細やかな対応をモットーとしております。どうぞお気軽にご相談下さい。
        <span class="font-weight-black grey--text text--darken-2">※当院では新型コロナ感染防止対策として消毒液による清拭、空気清浄機の稼働、定期的な換気、また患者様には来院時に検温、消毒を御協力頂いています。</span>
        </p>

               
      </v-row>

    
        
        <h2 
        style="
          margin-bottom: 1rem;
          padding: 0.3em;
          background: #4DD0E1;
          border-left: solid 10px #0097A7;
          border-radius: 10px;
        "
        class="white--text text-md-h3 text-h4 font-weight-black"
        >最近の治療例</h2>
        
     


      <!-- (STA)slide-groupでcard  -->
      <v-row>

      <v-slide-group multiple show-arrows>
       
        <v-slide-item v-for="list in displayLists" :key="list.id">
 
        <v-card
          shaped
          style="margin:10px; width: 250px; height: 400px;"
        >
          <v-img
            class="white--text align-end"
            :src="require('@/assets2/' + list.gazou1_b)"
            position="bottom 40% right 50%"
            alt="治療例の画像"
          >
            <v-card-title>{{list.name}}</v-card-title>
          </v-img>

            <v-card-subtitle class="pb-0">
              {{list.date}}
            </v-card-subtitle>

            <v-card-text class="text--primary">
              <div 
                  class="d-inline-block text-truncate"
                  style="max-width: 240px;"
              >{{list.naiyou}}</div>
            </v-card-text>
            <v-card-actions>
             <AppButton :button-text="buttonText" :url="url + (list.id)"/>
            </v-card-actions>

        </v-card>

        </v-slide-item>
      </v-slide-group>       

      </v-row>
      <!-- (END)slide-groupでcard  -->

    </v-container>
  </section>
</template>
<script>
import constants from '../common/constants'
import axios from 'axios'

import AppButton from './AppButton.vue'


export default {
  name :  'HomeAbout',
  data : function() {
    return {
      newsItems: constants.newsItems,
      displayLists: [],
      sinryou: [],
      //title: 'インプラント',
      buttonText: '治療詳細へ',
      url: '/tiryousyousai/',

    }
  },

  created() {
      axios.get('/functions/db_pickup.php').then((response) => {    // REP20220406
      // console.log(data); // works fine
      this.sinryou = response.data
      //this.tiryouRei = this.sinryou
      //console.log('success')
      //console.log(response.data)

      //axiousから取るときはココで以下すること！mountedでしたら取れない！
      //this.length = Math.ceil(this.sinryou.length/this.pageSize);

      //this.displayLists = this.sinryou.slice(this.pageSize*(this.page -1), this.pageSize*(this.page));
      this.displayLists = this.sinryou;

      //console.log('length  '+this.length)
      //console.log('displayLists  ' + this.displayLists)


    });
  },


  components: {
    AppButton,
  },


}




</script>
<style lang="scss" scoped>
.home-about {
  //margin-top: 20px;
  //justify-content: center;
  //color:#616161;

  &-text {
    color: #616161;
    //text-align: left;
    //font-family: 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', 'Hiragino Mincho ProN', 'HGS明朝E', 'ＭＳ Ｐ明朝', serif;
    font-size: 28px;
    font-weight: bold;
    //text-shadow:2px 2px black ;
    //top: 450px;
    //left: 5px;
    //right: auto;
    //width: auto;
    //padding: 0px 0px 0px 0px ;
    //margin-bottom: 20px;
    //background-color:rgba(0,204,204,0.25);
    //background-color: #0097A7;
    //padding: 0.2em;
    //border-radius: 5px;
    //position: absolute;

    @include display_pc {
        //font-size: 40px;
        //position: absolute;
    }

  }


}
</style>