<template>

  <section class="site">
    <AppBackgroundHolder :title="title"/>
  
    <v-container>
      <!-- パンくず -->
      <div>
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      </div>



      <template>
        <v-container class="vsheet__toc">
          <v-row no-gutters>
            <v-col
            >
              <v-card
                class="pa-2"
                outlined
                tile
                elevation="3"
              >

                <p>ネット予約について</p>
                  <ul>
                    <li>対象者
                      <ul>
                        <li>１．初診の方</li>
                        <li>２．再診の方で１回目の受診の方</li>
                        <li>３．定期検診の方で１回目の受診の方</li>
                        <li>を対象としています。上記以外の方はお電話にてお問合せ下さい。</li>
                      </ul>          
                    </li>

                    <li>対象日範囲
                      <ul>
                        <li>翌営業日以降の日を対象としています。急患の方はお電話にてお問合せ下さい。</li>
                      </ul>          
                    </li>

                    <li>予約成立
                      <ul>
                        <li>仮予約登録後、翌営業日以降に当医院より折り返し電話にて御連絡致します。連絡つき次第予約成立となります。（何らかの事情により翌営業日以降に当医院から連絡が来ない場合はお手数ですがお電話にて御連絡下さい）
          </li>
                      </ul>          
                    </li>

                  </ul>

              </v-card>
            </v-col> 
          </v-row>
        </v-container>
      </template>



      <h2 
      id="1"
      style="
        margin-bottom: 1rem;
        padding: 0.3em;
        background: #4DD0E1;
        border-left: solid 10px #0097A7;
        border-radius: 10px;
      "
      class="white--text text-md-h3 text-h4 font-weight-black"
      >仮予約登録</h2>


      <template>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="name"
            :counter="10"
            :rules="nameRules"
            label="お名前"
            required
          ></v-text-field>

          <v-text-field
            v-model="age"
            :counter="2"
            :rules="ageRules"
            label="年齢"
            suffix="歳"
            required
          ></v-text-field>  

          <p>性別</p>
          <v-radio-group
            v-model="sex"
            row mandatory
          >
            <v-radio
              label="男"
              value="1"
            ></v-radio>
            <v-radio
              label="女"
              value="2"
            ></v-radio>
          </v-radio-group>          



          <v-text-field
            v-model="telno"
            :rules="telnoRules"
            label="電話番号"
            required
          ></v-text-field>


          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="メールアドレス"
            required
          ></v-text-field>          
<!--
          <v-select
            v-model="select"
            :items="selects"
            :rules="[v => !!v || 'Item is required']"
            label="Item"
            required
          ></v-select>
        -->

      <p>診療希望日時</p>
      <v-row>
        <v-col
          cols="4"
        >
          <v-text-field
            v-model="mon1"
            :counter="2"
            :rules="ageRules"
            required
            prefix="第1希望"
            suffix="月"
          ></v-text-field>
        </v-col>

        <v-col
          cols="4"
        >
          <v-text-field
            v-model="day1"
            :counter="2"
            :rules="ageRules"
            required
            suffix="日"
          ></v-text-field>
        </v-col>

        <v-col
          cols="4"
        >
          <v-text-field
            v-model="time1"
            :counter="2"
            :rules="ageRules"
            required
            suffix="時頃"
          ></v-text-field>
        </v-col>        
      </v-row>
<!-- REP20220328 第2、第3希望必須なくす -->
      <v-row>
        <v-col
          cols="4"
        >
          <v-text-field
            v-model="mon2"
            :counter="2"
            prefix="第2希望"
            suffix="月"
          ></v-text-field>
        </v-col>

        <v-col
          cols="4"
        >
          <v-text-field
            v-model="day2"
            :counter="2"
            suffix="日"
          ></v-text-field>
        </v-col>

        <v-col
          cols="4"
        >
          <v-text-field
            v-model="time2"
            :counter="2"
            suffix="時頃"
          ></v-text-field>
        </v-col>        
      </v-row>  

      <v-row>
        <v-col
          cols="4"
        >
          <v-text-field
            v-model="mon3"
            :counter="2"
            prefix="第3希望"
            suffix="月"
          ></v-text-field>
        </v-col>

        <v-col
          cols="4"
        >
          <v-text-field
            v-model="day3"
            :counter="2"
            suffix="日"
          ></v-text-field>
        </v-col>

        <v-col
          cols="4"
        >
          <v-text-field
            v-model="time3"
            :counter="2"
            suffix="時頃"
          ></v-text-field>
        </v-col>        
      </v-row>
      <p>※診療時間／AM9時～PM1時、PM3時～PM7時 休診日／木・日・祝日</p>

      <p>受診内容</p>
      <v-radio-group
        v-model="jyusin"
        row mandatory
      >
        <v-radio
          label="初診"
          value="1"
        ></v-radio>
        <v-radio
          label="再診"
          value="2"
        ></v-radio>
        <v-radio
          label="定期検診"
          value="3"
        ></v-radio>
      </v-radio-group>     



        <v-textarea
          name="input-7-1"
          label="症状"
          :rules="textboxareaRules"
          v-model="textboxarea"
          value=""
          prepend-inner-icon="mdi-comment"
          hint="仮予約ありがとうございます"

          auto-grow
          outlined
          rows="5"
          row-height="25"
          shaped
        ></v-textarea>

          <v-checkbox
            v-model="checkbox"
            :rules="[v => !!v || 'チェックが必要です!']"
            label="業者の方や対応圏外の方からのお問合せには返信致しかねます。チェック願います。"
            required
          ></v-checkbox>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="clickbtn_send"
          >
            送信する
          </v-btn>

          <v-btn
            color="error"
            class="mr-4"
            @click="reset"
          >
            リセットする
          </v-btn>
<!--
          <v-btn
            color="warning"
            @click="resetValidation"
          >
            Reset Validation
          </v-btn>
-->

        </v-form>
      </template>


    

    </v-container>

<go-top
  max-width="50"
  bg-color="#0097A7"
  :size="50"
></go-top>

  </section>

</template>

<script>
import AppBackgroundHolder from './AppBackgroundHolder.vue'
import GoTop from '@inotom/vue-go-top'

import axios from 'axios'

export default {
  data () {
    return {
      title: 'ネット予約',

      //パンくず
      items: [
        {
          text: 'Home',
          disabled: false,
          //href: '../',
          to: '/',
        },
        {
          text: 'ネット予約',
          disabled: true,
          href: './order',
        },
      ],

     
      valid: true,
      name: '',
      nameRules: [
        v => !!v || '名前が必要です',
        v => (v && v.length <= 10) || '名前は10文字以下です',
      ],
      age: '',
      ageRules: [
        v => !!v || '入力が必要です',
        v => (v && v.length <= 2) || '入力は2文字以下です',
        v => /^[0-9]+$/.test(v) || '入力形式が誤っています',
      ],      
      email: '',
      emailRules: [
        v => !!v || 'E-mailが必要です',
        v => /.+@.+\..+/.test(v) || 'E-mail形式が誤っています',
      ],
      textboxarea: '',
      textboxareaRules: [
        v => !!v || '症状を入力して下さい',
        v => (v && v.length <= 1000) || '内容は1000文字以下です',
      ],
      telno: '',
      telnoRules: [
        v => !!v || '電話番号が必要です',
        v => /^[0-9]{3}-?[0-9]{3,4}-?[0-9]{3,4}$/.test(v) || '電話番号形式が誤っています',
      ],
      //↓これしたら初期値で出てしまうのでだめ
      //mon2: '00',
      //day2: '00',
      //time2: '00',
      //mon3: '00',
      //day3: '00',
      //time3: '00',
      day23Rules: [
        //valueが存在する、||(そうでない場合) 'error msg'
        //v => !!v || '入力が必要です',

        //!!v(valueが存在する) &&(かつ) length2以下でない 'error msg'
        v => (!!v && v.length <= 2) || '入力は2文字以下です',
        v => (!!v && /^[0-9]+$/.test(v)) || '入力形式が誤っています',
      ],     

      select: null,
      selects: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4',
      ],
      checkbox: false,
 

    }
  },

  components: {
    AppBackgroundHolder,
    GoTop,
    //Axios,
  
  },
    mounted: function(){
		document.title = "石岡歯科医院 ネット予約";
		document.querySelector("meta[name='description']").setAttribute('content', "石岡歯科医院ネット予約のページです。");
    // ADD20220406 ブラウザバックを無効化に伴い当URLは有効化するため
    //history.pushState({}, '', location.href);
	},

    methods: {

      validate () {
        alert("validate")
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },

      onclick : function(e) {
        /*
        if (this.$refs.test_form.validate()) {
          //ここに正常処理入れる
        }
        */

        console.log(e)
        //let name = this.name
        //let email = this.email
        //alert(this.name)
        //alert(this.email)

        fetch('https://www.kawanishi89.com/upload.php', {
          method:'POST',
          body1: this.name,
          body2: this.email,
        })
        .then((res) => res.text())

        .then(console.log)

        .catch(function(error) {
          window.alert('Error: ' + error.message);
        });

      },

        
        clickbtn_send: function () {
            
					//mailerrorboxとnameerrorboxがそれぞれfalseであれば、エラー無しと判断。
					//if(mailerrorbox == "false" && nameerrorbox == "false"){

          if (this.$refs.form.validate()) {
            //alert(this.email)
            //alert(this.name);
						//axiosという機能を使ってPHPファイルにデータを送信します。
						//メールはPHPなどのバックエンド側の言語でしか送れません。
						//よって、Vue.jsからPHPにデータを渡す必要があります。
						//下記はデータをPHPに投げる時の1セットだと思ってください。
						let params = new URLSearchParams();
						//mailareaという箱の中にdata、mailareに入力された内容を入れます。
						params.append('mailarea', this.email);
						//nameareaという箱の中にdata、nameareaに入力された内容を入れます。
						params.append('namearea', this.name);
            //telnoareaという箱の中にdata、telnoに入力された内容を入れます。
						params.append('telnoarea', this.telno);
						//textboxareaという箱の中にdata、textboxareaに入力された内容を入れます。
						params.append('textboxarea', this.textboxarea);

            params.append('agearea', this.age);
            params.append('sexarea', this.sex);
            params.append('mon1area', this.mon1);
            params.append('day1area', this.day1);
            params.append('time1area', this.time1);
            params.append('mon2area', this.mon2);
            params.append('day2area', this.day2);
            params.append('time2area', this.time2);
            params.append('mon3area', this.mon3);
            params.append('day3area', this.day3);
            params.append('time3area', this.time3);
            params.append('jyusinarea', this.jyusin);
            


						//axiosという機能を使いデータを投げます。REP20220406 /functions
						axios.post('/functions/order.php', params)
						//PHPで処理された結果がresponseに帰ってきます。
						.then(function (response) {
							//PHPで処理された結果はresponse.dataで呼び出せます。
							//今回は、送信完了メッセージが入っています。
							//詳しくはPHPファイルを確認してみてください。
							//alertで送信完了メッセージを出します。
							alert(response.data)
							//フォームのトップページにリダイレクトします。
							document.location = "./"
						})
						.catch(function() {

							//何かエラーが起きたらconsole.logにエラーを表示させます。
							console.log("error");
						})
					//} else {
          //  alert("error");
          }

				},   


      /*
      submit () {
        this.$refs.observer.validate()
      },
      clear () {
        this.name = ''
        this.phoneNumber = ''
        this.email = ''
        this.select = null
        this.checkbox = null
        this.$refs.observer.reset()
      },
      */
    },

  head: {
      // link tags
      link: [
        { rel: 'canonical', href: 'https://www.ishiokashika.net/order/' },  //REP20220523
      ],
  },  

}
</script>

<style lang="scss" scoped>

.vsheet__toc {
  color:#616161; //textはgray-darken2
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  
  p {font-size: 24px;text-align: center;}

  a {text-decoration: none !important;color: #616161; }
  a:hover {text-decoration: underline !important;}
  li {padding-top:10px; list-style-type: none !important;}

}

</style>