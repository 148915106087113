<template>
  <section class="home-ippan">

    <v-container>

    <h2 
    style="
      margin-bottom: 1rem;
      padding: 0.3em;
      background: #4DD0E1;
      border-left: solid 10px #0097A7;
      border-radius: 10px;
    "
    class="white--text text-md-h3 text-h4 font-weight-black"
    >一般歯科・小児歯科</h2>

      <v-row>
        <v-col>
          <h3
          style="
            border-left: solid 10px  #0097A7;
            border-radius: 10px;
          "
          class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
          >急患の方へ</h3>

          <v-img
                  :src="require('@/assets2/toiawase_s.jpg')"
                  alt="一般歯科・虫歯"
                  max-width="739"
                  max-height="250"
                ></v-img>


          <p>
            急患の方はまずはお電話にてお問合せ下さい。適切な治療をさせて頂きます。またご希望に応じて無痛治療も行っておりますので安心してご依頼下さい。
            しかし本来であれば虫歯や歯周病にならないために、日頃の生活習慣を改善し予防することが大事です。歯石をためないよう定期的な検診をお勧め致します。当医院では正しい歯の磨き方の指導や歯周病に関する指導なども行っておりますのでお気軽にご相談下さい。特に子供のうちに正しい歯磨きを覚えることは非常に大切です。
          </p>
    
        </v-col>
      </v-row>
      <!-- <AppButton :button-text="buttonText" :url="url"/> -->
      <AppButton :button-text="buttonText" :url="{name: 'toiawase'}"/> 

    </v-container>


  </section>
</template>
<script>
 
import AppButton from './AppButton.vue'
 
export default {
  data () {
    return {
      buttonText: 'お問合せ',
      url: '/toiawase/3',

      
    }
  },
  components: {
    AppButton
  }
}
</script>
<style lang="scss" scoped>
.home-ippan {
  margin-top: 20px;
  justify-content: center;
  color:#616161;

  
}

</style>