<template>
  <section class="home">
    <!-- <h2>{{ this.$store.state.message }}</h2> -->
    <!-- <h2>{{ $route.query.id }}</h2> -->
    <HomeHero></HomeHero>
    <HomeAbout/>
    <HomeIppan/>
    <HomeSinryou/>
    <HomeOrder/>
    <!-- <HomeMap/> -->


<!-- TOPへ戻るボタン -->
<go-top
  max-width="50"
  bg-color="#0097A7"
  :size="50"
>

      </go-top>

  </section>



</template>
<script>
import HomeHero from './HomeHero.vue'
import HomeAbout from './HomeAbout.vue'
import HomeIppan from './HomeIppan.vue'
import HomeSinryou from './HomeSinryou.vue'
import HomeOrder from './HomeOrder.vue'
//import HomeMap from './HomeMap.vue'

import GoTop from '@inotom/vue-go-top'

 


export default {
  name: 'Home',

  components: {
    HomeHero,
    HomeAbout,
    HomeIppan,
    HomeSinryou,
    HomeOrder,
    //HomeMap,

   GoTop,
   

  },
  mounted: function(){
		document.title = "伊丹市の歯医者 石岡歯科医院";
		document.querySelector("meta[name='description']").setAttribute('content', "一般・小児歯科、矯正、インプラント、美容歯科、ホワイトニングなど患者様個々のニーズに応じたキメ細やかな対応をモットーとしております。");
    
    // ADD20220406 ブラウザバックを無効化に伴い当URLは有効化するため
    history.pushState({}, '', location.href);
  },

    methods : {
    /** 下記と同じ事、省略形
    message () { return this.$store.state.message }
    */
    message : function() {
       //return this.$store.state.message 
       this.$store.commit('setMessage','Hellllo')
    }
  },

  data () {
    return {
      buttonText: '詳しくみる',
    }
  },

  head: {
      // link tags
      link: [
        { rel: 'canonical', href: 'https://www.ishiokashika.net/' },
      ],
  },
  
}




</script>

<style lang="scss" scoped>
.home {
  padding-bottom: 50px;
}
</style>