import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'

//import VueAxios from 'vue-axios'

//import Home from './components/Home.vue'

//import GoTop from '@inotom/vue-go-top'
 
//Vue.use(GoTop);

import Vuex from 'vuex';
// index.jsの場合はファイル名を指定する必要はない
import store from './store'
Vue.use(Vuex);

//v-pagination対応時
//import Pagination from '@/plugins/pagination.js'
//Vue.use(Pagination);

//vuejs-pagenate対応時
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

import VueHead from 'vue-head'
Vue.use(VueHead)

 
Vue.config.productionTip = false

Vue.prototype.$axios = axios
//Vue.use(VueAxios, axios) 


// ブラウザバックを検知してコンポーネントで「this.$browserBackFlg」で使用できるようにする。
/* paginationはダメだった！！！！
Vue.prototype.$browserBackFlg = false
history.replaceState(null, '', null)
window.addEventListener('popstate', function() {
  Vue.prototype.$browserBackFlg = true

  window.setTimeout(() => {
    Vue.prototype.$browserBackFlg = false
  }, 500)
})
*/

new Vue({
  store,

 // Home,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
