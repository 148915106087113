<!--  
(注意)
1.bootstrap css指定
2.rooter.js constants.jsにて:id指定
3.main.js指定
-->
<!-- REP20220406 -->
<template>

  <section class="inplant">
    <AppBackgroundHolder :title="title"/>
  
    <v-container>
      <!-- パンくず -->
      <div>
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      </div>

      <template>
        <v-container class="vsheet__toc">
          <v-row no-gutters>
            <v-col
            >
              <v-card
                class="pa-2"
                outlined
                tile
                elevation="3"
              >

                <p>目次</p>
                  <ul>
                    <li><a @click="scrollToAnchorPoint('1')">1. インプラント治療について</a>
                      <ul>
                        <li><a @click="scrollToAnchorPoint('1-1')">1-1. インプラントはどのような治療法か？</a></li>
                        <li><a @click="scrollToAnchorPoint('1-2')">1-2. インプラント治療の流れ</a></li>
                      </ul>          
                    
                    </li>
                    <li><a @click="scrollToAnchorPoint('2')">2. インプラント治療は誰でも受ける事ができるのですか？</a></li>
                    <li><a @click="scrollToAnchorPoint('3')">3. インプラント治療は痛みはあるのですか？期間はどれくらいかかるのでしょうか？</a></li>
                    <li><a @click="scrollToAnchorPoint('4')">4. インプラントはどのくらいもつのでしょうか？</a></li>
                    <li><a @click="scrollToAnchorPoint('5')">5. インプラント治療費用はどれくらいかかるのでしょうか？</a></li>
                    <li><a @click="scrollToAnchorPoint('6')">6. インプラントの治療例</a></li>
                  </ul>

              </v-card>
            </v-col> 
          </v-row>
        </v-container>
      </template>




      <h2 
      ref="1"
      style="
        margin-bottom: 1rem;
        padding: 0.3em;
        background: #4DD0E1;
        border-left: solid 10px #0097A7;
        border-radius: 10px;
      "
      class="white--text text-md-h3 text-h4 font-weight-black"
      >1. インプラント治療について</h2>

      <h3
      ref="1-1"
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >1-1. インプラントはどのような治療法か？</h3>
      
      <div>１．入れ歯が合わなくて悩んでいる方</div>
      <div>２．健康な歯を削るのがいやな方</div>
      <div>３．入れ歯ではなく自然な歯並びを取り戻したい方</div>
      <p>     等の症状の方を対象として、歯が抜けた場合で両隣の健康な歯を削ることなく歯の抜けたところへインプラントを植え込み人工の歯を取り付けます。歯が全部抜けた場合、従来であれば入れ歯をつけるだけで固定するものがなく動いたりしますが、数本のインプラントを植え込み入れ歯を維持装置で固定すれば動くことはありません。</p>

      <h3
      ref="1-2"
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >1-2. インプラント治療の流れ</h3>
      <div>１．レントゲン等で診察し治療方針を決定し御説明致します。</div>
      <div>２．歯の抜けた場所にインプラントを植え込みます。固定されるまで３～６ヶ月待ちます。</div>
      <div>３．インプラントの上に人工歯を取り付けて完了です。</div>
      <div>４．半年に１回は定期検診を受けるようにして下さい。</div>
      
      <h2 
      ref="2"
      style="
        margin-bottom: 1rem;
        padding: 0.3em;
        background: #4DD0E1;
        border-left: solid 10px #0097A7;
        border-radius: 10px;
      "
      class="white--text text-md-h3 text-h4 font-weight-black"
      >2. インプラント治療は誰でも受ける事ができるのですか？</h2>

      <p>心臓病や糖尿病の方、妊娠中の方などは受けれない場合があります。また、あごの骨の状態によっても受けれない場合があります。いずれも事前にご相談下さい。</p>
      <h2 
      ref="3"
      style="
        margin-bottom: 1rem;
        padding: 0.3em;
        background: #4DD0E1;
        border-left: solid 10px #0097A7;
        border-radius: 10px;
      "
      class="white--text text-md-h3 text-h4 font-weight-black"
      >3. インプラント治療は痛みはあるのですか？期間はどれくらいかかるのでしょうか？</h2>
      <p>簡単な麻酔を行いますのでほとんど痛みはありません。インプラントの種類や治療内容により異なりますが一般的に処置時間は３０～１２０分、治療期間は４～１２ヶ月かかります。</p>

      
      <h2 
      ref="4"
      style="
        margin-bottom: 1rem;
        padding: 0.3em;
        background: #4DD0E1;
        border-left: solid 10px #0097A7;
        border-radius: 10px;
      "
      class="white--text text-md-h3 text-h4 font-weight-black"
      >4. インプラントはどのくらいもつのでしょうか？</h2>
      <p>腐食しにくいチタン金属でできています。できるだけ長持ちさせるためには自分の歯と同じような手入れが必要です。</p>

      <h2 
      ref="5"
      style="
        margin-bottom: 1rem;
        padding: 0.3em;
        background: #4DD0E1;
        border-left: solid 10px #0097A7;
        border-radius: 10px;
      "
      class="white--text text-md-h3 text-h4 font-weight-black"
      >5. インプラント治療費用はどれくらいかかるのでしょうか？</h2>
      <p>インプラントの本数・種類により費用は異なりますのでご相談下さい。</p>

      <v-img
                  :src="require('@/assets2/inplant.jpg')"
                  alt="インプラント10年保障"
                  max-width="240"
                  max-height="320"
                ></v-img>
      <p>
      <a href="http://www.guidedent.co.jp"  style="color:#616161 ; text-decoration: underline; ">http://www.guidedent.co.jp</a>
      </p>

      <h2 
      ref="6"
      style="
        margin-bottom: 1rem;
        padding: 0.3em;
        background: #4DD0E1;
        border-left: solid 10px #0097A7;
        border-radius: 10px;
      "
      class="white--text text-md-h3 text-h4 font-weight-black"
      >6. インプラントの治療例</h2>


<!--kokokara -------------------------------------------------------->

      <v-row class="home-inplant__contents">    
        <!--  
        <v-col cols="12" md="3"  v-for="(newsItem, index) in 30"
      :key="index"> -->
      <!-- md="2"でLAPTOP以上は横6(= 2/12)画面ずつ出す -->
        <v-col cols="12" md="3"  v-for="list in displayLists" :key="list.id">
          <v-card
            shaped
          >
          <!--(参考)
           <v-img :src="require('@/assets/sample.jpg')"></v-img> <<@ or .. でrequire要!

           :src= "'../assets2/' + list.gazou1_b" <<テキストと変数合成
          -->
            <v-img
              class="white--text align-end"
              :src="require('@/assets2/' + list.gazou1_b)"
              position="bottom 40% right 50%"
              alt="治療例の画像"
            >
              <v-card-title>{{list.name}}</v-card-title>
            </v-img>

            <v-card-subtitle class="pb-0">
              {{list.date}}
            </v-card-subtitle>

            <v-card-text class="text--primary">
              <div 
                  class="d-inline-block text-truncate"
                  style="max-width: 240px;"
              >{{list.naiyou}}</div>
            </v-card-text>
            <v-card-actions>
             <!-- <AppButton :button-text="buttonText" :url="url + (list.id)"/> -->
             <AppButton :button-text="buttonText" :url="{name: 'tiryousyousai', params: { id: list.id }}"/> 
            </v-card-actions>
          </v-card>
        </v-col>

      </v-row>  
      <!-- 
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="length"
          @input = "pageChange"
        ></v-pagination>
      </div>
      -->

      <!-- vuejs-pagenate  -->
      <!-- <link rel="stylesheet" href="https://cdn.bootcss.com/bootstrap/3.3.5/css/bootstrap.css">-->
      <div id="app" class="container">
        <!--
        <a href="">test</a>
        <h2>page {{currentPage}}</h2>
        -->

        <paginate
          v-model="currentPage"
          :page-count="getPageCount"
          :initial-page="4"
          :page-range="3"
          :margin-pages="2"
          :click-handler="clickCallback"
          :prev-text="'＜'"
          :next-text="'＞'"
          :container-class="'pagination'"
          :page-class="'page-item'">
        </paginate>
      </div>
      <!-- vuejs-pagenate  --> 

<!-- kokomade -------------------------------------------------->

    </v-container>

<go-top
  max-width="50"
  bg-color="#0097A7"
  :size="50"
></go-top>

  </section>

</template>
<script>
import AppBackgroundHolder from './AppBackgroundHolder.vue'
import GoTop from '@inotom/vue-go-top'

import axios from 'axios'

import AppButton from './AppButton.vue'

//vuejs-pagenate
import Paginate from 'vuejs-paginate'

 
export default {
  data () {
    return {
      page: 1,
      length:0,
      lists: [],
      displayLists: [],
      pageSize: 4,      //1ページに表示するデータ数 vuejs-pagenate
      //pageSize: 12,      

      sinryou: [],

      title: 'インプラント',

      buttonText: '治療詳細へ',
      url: '/tiryousyousai/',

      //パンくず
      items: [
        {
          text: 'Home',
          disabled: false,
          //href: '/',
          to: '/'
        },
        {
          text: 'インプラント',
          disabled: true,
          //href: './inplant',
          to: '/inplant/'
        },    
      ],    
      //vuejs-pagenate
      //(REP D20220328) parPage: 10,
      parPage: 4,
      currentPage: this.currentPage = this.$route.params.id || 1,   
    }
  },

  watch: {
    '$route'(to,) {
      this.currentPage = to.params.id;
      //alert(to.params.id);
      //alert(from.params.id);
      //next();
    },
    
  },  
  

  methods: {
    /*
    pageChange: function(pageNumber){
      this.displayLists = this.sinryou.slice(this.pageSize*(pageNumber -1), this.pageSize*(pageNumber));
    },
    */

  //vuejs-pagenate
    clickCallback: function () {
      this.displayLists = this.sinryou.slice(this.pageSize*(this.currentPage -1), this.pageSize*(this.currentPage));
      //alert(location.href);
      this.$router.push({ 
        name: 'inplant', 
        params: { 
          id: this.currentPage 
        }
      });   

      //test logic DEL20220406
      //location.reload(true);
      
      //alert(location.href);
      //alert(location.hash);
    },

    //hash logic
    parseHash: function() { // ハッシュからパラメータを取り出す

        this.updateItem();

        // これに変えてrouter.go()リロードにしてもダメ！
        this.$router.push({ 
          name: 'inplant', 
          params: { 
          id: this.currentPage
          }
        });

  
    },

    updateItem: function(){
        axios.get('/functions/db_inplant.php').then((response) => {    //d20220406
              // console.log(data); // works fine
              this.sinryou = response.data
              //this.tiryouRei = this.sinryou
              console.log('success')
              console.log(response.data)

              //axiousから取るときはココで以下すること！mountedでしたら取れない！
              this.length = Math.ceil(this.sinryou.length/this.pageSize);
              //this.displayLists = this.sinryou.slice(this.pageSize*(this.page -1), this.pageSize*(this.page));

              this.displayLists = this.sinryou.slice(this.pageSize*(this.currentPage -1), this.pageSize*(this.currentPage));
              //this.displayLists = this.sinryou.slice(this.pageSize*(5 -1), this.pageSize*(5));

              //console.log('length  '+this.length)
              //console.log('displayLists  ' + this.displayLists)
        }
      );
    },

    //test logic
    /*
    handleScroll() {
        this.scrollY = window.scrollY;
    },
    */

    //アンカータグ
    scrollToAnchorPoint(refName) {
            const el = this.$refs[refName]
            el.scrollIntoView({ behavior: 'smooth'})
    }   

  },

  //vuejs-pagenate
  computed: {
    getItems: function() {
      return this.sinryou;
    },
    getLists: function(){
      let current = this.currentPage * this.parPage;
      let start = current - this.parPage;
      return this.getItems.slice(start, current);
    },
    getPageCount: function() {
      return Math.ceil(this.getItems.length / this.parPage);
    }
  },   


  created() {
      axios.get('/functions/db_inplant.php').then((response) => {    //d20220406
      // console.log(data); // works fine
      this.sinryou = response.data
      //this.tiryouRei = this.sinryou
      //console.log('success')
      //console.log(response.data)

      //axiousから取るときはココで以下すること！mountedでしたら取れない！
      this.length = Math.ceil(this.sinryou.length/this.pageSize);
      this.displayLists = this.sinryou.slice(this.pageSize*(this.page -1), this.pageSize*(this.page));
      //console.log('length  '+this.length)
      //console.log('displayLists  ' + this.displayLists)


    });
  },


  components: {
    AppBackgroundHolder,
    GoTop,
    AppButton,
    Paginate,
  },

  mounted: function(){
		document.title = "伊丹市の歯医者 インプラント治療";
		document.querySelector("meta[name='description']").setAttribute('content', "インプラント治療例 - 伊丹市の歯医者石岡歯科医院");

    /*
    this.lists = new Array(99).fill().map((v,i)=> {
      return { id : i,title : 'Title' + i };
    });
    
    this.length = Mah.ceil(this.lists.length/this.pageSize);
    this.displayLists = this.lists.slice(this.pageSize*(this.page -1), this.pageSize*(this.page));
    */

    //hash logoc vuejs-pagenate  DEL20220406
    //window.addEventListener('hashchange', this.parseHash); //ハッシュ値が変更になったときに発生するイベント ↓↑逆意味か？
    //this.parseHash();      

	},

  head: {
      // link tags
      link: [
        { rel: 'canonical', href: 'https://www.ishiokashika.net/inplant/1/' },  //d20220523
      ],
  },  

}
</script>
<style lang="scss" scoped>
.inplant {
  color:#616161;
}

.vsheet__toc {
  color:#616161; //textはgray-darken2
  padding-top: 20px;
  margin-bottom: 20px;
  
  p {font-size: 24px;text-align: center;}

  a {text-decoration: none !important;color: #616161; }
  a:hover {text-decoration: underline !important;}
  li {padding-top:10px; list-style-type: none !important;}

}

</style>

<style lang="css">
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 3;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

</style>