<template>
  <section class="home-hero"> <!--これでma-0空白なくなる-->
    <v-container fluid  class="home-hero__content">
    <!--
      <v-row>
        <v-col class="home-hero__content-text">
          <p class=" red--text text-sm-h1">やさしい、明るい歯医者さんです</p>
       
          <p class="white--text text-sm-h2">皆様のお越しをお待ちしています。</p>
        </v-col>
      </v-row>
     --->

      <!-- (STA)カルーセル -->

      
      <v-row>
        <v-carousel
          cycle
          height="600px"
        >
          <v-carousel-item
            v-for="(topheroItem,i) in topheroItems"
            :key="i"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
          <v-img v-bind:src="topheroItem.src" v-bind:lazy-src="topheroItem.src" 
            alt="TOP画像" 
           
            position="right 80% bottom 70%"
            width="100%"
            height="100%"
          >
           <!-- position="right 5% bottom 70%"-->
            
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="blue-grey"
                 
                ></v-progress-circular>
               
              </v-row>
            </template>
            <div class="home-hero__content-text">確かな技術力により開院以来多くの患者様からの信頼を頂いています</div>
          </v-img>
        </v-carousel-item>
        </v-carousel>
      </v-row>
      <!-- (END)カルーセル -->


    </v-container>
  </section>
</template>


<script>
import constants from '../common/constants'

export default {

  data () {
    return {
      topheroItems: constants.topheroItems,
    }
  },

}
</script>

<style  lang="scss" scoped>
.home-hero__content {

  height: 600px;

  @include display_pc {
       height: 600px;
  }
 
  &-text {
    color: white;
    text-align: left;
    font-family: 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', 'Hiragino Mincho ProN', 'HGS明朝E', 'ＭＳ Ｐ明朝', serif;
    font-size: 28px;
    font-weight: bold;
    text-shadow:2px 2px black ;
    top: 410px;
    left: 5px;
    right: auto;
    width: auto;
    padding: 0px 0px 0px 0px ;
    margin: 0px;
   
    background-color:rgba(0,204,204,0.25);
    padding: 0.2em;
    border-radius: 10px;
   position: absolute;

    @include display_pc {
        font-size: 40px;
        position: absolute;
    }
  }
}
.siteName {
  font: 10px Arial,sans-serif;
  text-align: right;
  position: absolute;
  top: 0px;
  left: auto;
  right: 5px;
  width: auto;
  padding: 0px 0px 0px 0px ;
  margin: 0px;
  color: #cccccc;  
 
  @include display_pc {
    font: bold 80% Arial,sans-serif;
    text-align: right;
    position: absolute;
    top: 0px;
    left: auto;
    right: 5px;
    width: auto;
    padding: 0px 0px 0px 0px ;
    margin: 0px;
    color: #cccccc;    
  }
}


</style>