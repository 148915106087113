<template>
  <section class="home-sinryou">


    <v-container>

          <h2 
            style="
              margin-bottom: 1rem;
              padding: 0.3em;
              background: #4DD0E1;
              border-left: solid 10px #0097A7;
              border-radius: 10px;
            "
            class="white--text text-md-h3 text-h4 font-weight-black"
            >診療内容
          </h2>

          <h3
          style="
            border-left: solid 10px  #0097A7;
            border-radius: 10px;
          "
          class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
          >綺麗な美しい歯並びは好印象を与えます</h3>

          <p>美しい歯並びは好印象を与えます。「生まれつき歯並びが悪いから．．．」と諦めていませんか？</p>
          <p>歯は正しい治療次第で美しく生まれ変わります。<router-link :to="{ name : 'inplant', params: { id: '1' }}"  style="color:#616161 ; text-decoration: underline; ">インプラント</router-link>、<router-link :to="{ name : 'kyousei', params: { id: '1' }}"  style="color:#616161 ; text-decoration: underline; ">矯正治療</router-link>については現在の様に普及する以前から力を入れておりましたので是非技術的にも安心な当医院におまかせください。<router-link :to="{ name : 'sinbi', params: { id: '1' }}"  style="color:#616161 ; text-decoration: underline; ">審美・美容治療</router-link>や<router-link :to="{ name : 'whitening', params: { id: '1' }}"   style="color:#616161 ; text-decoration: underline; ">ホワイトニング</router-link>、<router-link :to="{ name : 'ireba', params: { id: '1' }}"   style="color:#616161 ; text-decoration: underline; ">入れ歯治療</router-link>にも力を入れておりますのでご相談下さい。</p>
          <p>当医院には宝塚歌劇団のトップスターの方も通院しておられます。
<span style="color:#FF6666 ; font-size:large;">「タカラジェンヌも通う歯科医院」に貴方も是非お越し下さい。</span></p>
          <p style="color:#FF6666 ;">※当院のインプラント治療は10年保証の認定を受けています。</p>


      <v-row class="home-sinryou__contents">

        <v-col md="4"> <!--md="4"でLAPTOP以上は横3(= 4/12)画面ずつ出す -->
          <v-card
            shaped
          >
            <v-img
              class="white--text align-end"
              src= '../assets2/1194009.png'
            
              position="bottom 40% right 50%"
            >
              <v-card-title>インプラント</v-card-title>
            </v-img>

            <!--
            <v-card-subtitle class="pb-0">
              2021-01-01
            </v-card-subtitle>
            -->

            <v-card-text class="text--primary">
              <div 

              >インプラント治療とは歯の抜けたところへインプラントを植え込み人工の歯を埋め込む治療法です。</div>
            </v-card-text>

            <v-card-actions>

             <!-- <AppButton :button-text="buttonText_i" :url="url_i"/> -->
             <AppButton :button-text="buttonText_i" :url="{name: 'inplant', params: { id: '1' }}"/> 

            </v-card-actions>
          </v-card>
        </v-col>

        <v-col md="4">
          <v-card
            shaped
          >
            <v-img
              class="white--text align-end"
              src= '../assets2/22073160_s.jpg'
              position="bottom 40% right 50%"
              :aspect-ratio="15/10"
              height="auto"
              width="auto"
            >
              <v-card-title>ホワイトニング</v-card-title>
            </v-img>

            <!--
            <v-card-subtitle class="pb-0">
              2021-01-01
            </v-card-subtitle>
            -->

            <v-card-text class="text--primary">
              <div 

              >厚生省から認可された安全なホワイトニングです。歯や口元のコンプレックスをなくすことで、見た目のイメージや精神面までも変えることができるのです。</div>
            </v-card-text>

            <v-card-actions>

             <!-- <AppButton :button-text="buttonText_w" :url="url_w"/> -->
             <AppButton :button-text="buttonText_w" :url="{name: 'whitening', params: { id: '1' }}"/> 

            </v-card-actions>
          </v-card>
        </v-col>

        <v-col md="4">
          <v-card
            shaped
          >
            <v-img
              class="white--text align-end"
              src= '../assets2/1439305_s.jpg'
              position="bottom 40% right 50%"
            >
              <v-card-title>矯正歯科</v-card-title>
            </v-img>

            <!--
            <v-card-subtitle class="pb-0">
              2021-01-01
            </v-card-subtitle>
            -->

            <v-card-text class="text--primary">
              <div 

              >取り外しのできる装置、固定式装置などがあり固定式には金属のものと目立ちにくい白色セラミック等色々な種類の矯正装置を取り扱っています。</div>
            </v-card-text>

            <v-card-actions>

             <!-- <AppButton :button-text="buttonText_k" :url="url_k"/> -->
             <AppButton :button-text="buttonText_k" :url="{name: 'kyousei', params: { id: '1' }}"/> 

            </v-card-actions>
          </v-card>
        </v-col>

        <v-col  md="4">
          <v-card
            shaped
          >
            <v-img
              class="white--text align-end"
              src= '../assets2/3875359_m.jpg'
              position="bottom 40% right 50%"
            >
              <v-card-title>歯周病</v-card-title>
            </v-img>

            <!--
            <v-card-subtitle class="pb-0">
              2021-01-01
            </v-card-subtitle>
            -->

            <v-card-text class="text--primary">
              <div 

              >歯周病は歯をなくす最も大きな原因です。"ここ数年歯医者に行っていないなぁ..."と心あたりのある方はお気軽にご相談下さい。</div>
            </v-card-text>

            <v-card-actions>

             <!-- <AppButton :button-text="buttonText_s" :url="url_s"/> -->
             <AppButton :button-text="buttonText_s" :url="{name: 'sisyu', params: { id: '1' }}"/> 

            </v-card-actions>
          </v-card>
        </v-col>

        <v-col md="4">
          <v-card
            shaped
          >
            <v-img
              class="white--text align-end"
              src= '../assets2/2262570_s.jpg'
              position="bottom 40% right 50%"
            >
              <v-card-title>審美歯科</v-card-title>
            </v-img>

            <!--
            <v-card-subtitle class="pb-0">
              2021-01-01
            </v-card-subtitle>
            -->

            <v-card-text class="text--primary">
              <div 

              >歯のコンプレックスをなくす方法、その答えが審美治療です。当医院には宝塚歌劇団のトップスターの方も通院しておられます。</div>
            </v-card-text>

            <v-card-actions>

             <!-- <AppButton :button-text="buttonText_sn" :url="url_sn"/> -->
             <AppButton :button-text="buttonText_sn" :url="{name: 'sinbi', params: { id: '1' }}"/> 

            </v-card-actions>
          </v-card>
        </v-col>

        <v-col md="4">
          <v-card
            shaped
          >
            <v-img
              class="white--text align-end"
              src= '../assets2/3987071_m.jpg'
              position="bottom 40% right 50%"
            >
              <v-card-title>歯科検診</v-card-title>
            </v-img>

            <!--
            <v-card-subtitle class="pb-0">
              2021-01-01
            </v-card-subtitle>
            -->

            <v-card-text class="text--primary">
              <div 
                 
                  
              >定期的は歯科検診は非常に大切です。定期健診をする事で歯を長持ちさせることができます。下記よりご予約願います。</div>
            </v-card-text>

            <v-card-actions>

             <!-- <AppButton :button-text="buttonText_kn" :url="url_kn"/> -->
             <AppButton :button-text="buttonText_kn" :url="{name: 'order'}"/> 

            </v-card-actions>
          </v-card>
        </v-col>
        
 




      </v-row>

    </v-container>



  </section>
</template>

<script>
 
import AppButton from './AppButton.vue'
import constants from '../common/constants'

export default {
  data () {
    return {
      buttonText_i: 'インプラントへ',
      url_i: '/inplant/7',
      buttonText_w: 'ホワイトニングへ',
      url_w: '/whitening/6',
      buttonText_s: '歯周病へ',
      url_s: '/sisyu/8',
      buttonText_sn: '審美歯科へ',
      url_sn: '/sinbi/9',
      buttonText_k: '矯正歯科へ',
      url_k: '/kyousei/5',
      buttonText_kn: '歯科検診へ',
      url_kn: '/order/2',
      newsItems: constants.newsItems,
    }
  },
  components: {
    AppButton
  }
}
</script>
<style lang="scss" scoped>
.home-sinryou {
  color:#616161;
}
.home-sinryou__contents {
  margin-top: 20px;
  justify-content: center;
 
  &-img {
    text-align: center;
  }
 
  &-text {
    word-wrap: break-word;
  }
}
</style>