import Vue from 'vue'
import Vuex from 'vuex'
import 'es6-promise/auto'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    message:'hello vuex'
  },
  mutations: {
    setMessage : function(state, cmessage) {
      state.message = cmessage
      }
  },
  actions: {
  },
  modules: {
  }
})