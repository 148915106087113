<template>

  <section class="site">
    <AppBackgroundHolder :title="title"/>
  
    <v-container>
      <!-- パンくず -->
      <div>
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      </div>

      <v-row class="home-about__contents">
        <v-col  class="home-about__contents-img">
    
          <!--  <router-link v-bind:to="{ name: 'home' }" -->
          <div><router-link v-bind:to="{ name: 'home' }" class="text-body1" style="color:black; text-decoration: none; ">・Home</router-link></div>
          <div><router-link v-bind:to="{ name: 'kyousei', params: { id: '1' } }" class="text-body1 " style="color:black; text-decoration: none; ">・矯正歯科</router-link></div>
          <div><router-link v-bind:to="{ name: 'whitening', params: { id: '1' } }" class="text-body1 " style="color:black; text-decoration: none; ">・ホワイトニング</router-link></div>
          <div><router-link v-bind:to="{ name: 'inplant', params: { id: '1' } }" class="text-body1 " style="color:black; text-decoration: none; ">・インプラント</router-link></div>
          <div><router-link v-bind:to="{ name: 'sisyu', params: { id: '1' } }" class="text-body1 " style="color:black; text-decoration: none; ">・歯周病</router-link></div>
          <div><router-link v-bind:to="{ name: 'sinbi', params: { id: '1' } }" class="text-body1 " style="color:black; text-decoration: none; ">・審美歯科</router-link></div>
          <div><router-link v-bind:to="{ name: 'ireba', params: { id: '1' } }" class="text-body1 " style="color:black; text-decoration: none; ">・入れ歯</router-link></div>
          <div><router-link v-bind:to="{ name: 'tiryourei', params: { id: '1' } }" class="text-body1 " style="color:black; text-decoration: none; ">・治療例</router-link></div>
          <div><router-link v-bind:to="{ name: 'iin' }" class="text-body1 " style="color:black; text-decoration: none; ">・医院紹介</router-link></div>
          <div><router-link v-bind:to="{ name: 'intyou' }" class="text-body1 " style="color:black; text-decoration: none; ">・院長紹介</router-link></div>
          <div><router-link v-bind:to="{ name: 'order' }" class="text-body1 " style="color:black; text-decoration: none; ">・ネット予約</router-link></div>
          <div><router-link v-bind:to="{ name: 'toiawase' }" class="text-body1 " style="color:black; text-decoration: none; ">・お問合せ</router-link></div>
          <div><router-link v-bind:to="{ name: 'site' }" class="text-body1 " style="color:black; text-decoration: none; ">・サイトマップ</router-link></div>

        </v-col>
      </v-row>
    

    </v-container>

<go-top
  max-width="50"
  bg-color="#2196F3"
  :size="50"
></go-top>

  </section>

</template>
<script>
import AppBackgroundHolder from './AppBackgroundHolder.vue'
import GoTop from '@inotom/vue-go-top'
import constants from '../common/constants'
 
export default {
  data () {
    return {
      title: 'サイトマップ',
      menuItems: constants.menuItems,

      //パンくず
      items: [
        {
          text: 'Home',
          disabled: false,
          href: '../',
        },
        {
          text: 'サイトマップ',
          disabled: true,
          //href: './site',
          to: '/site/',
        },
      ],      
    }
  },
  components: {
    AppBackgroundHolder,
    GoTop
  },
  mounted: function(){
		document.title = "石岡歯科医院　サイトマップ";
		document.querySelector("meta[name='description']").setAttribute('content', "サイトマップ - 石岡歯科医院");

    // ADD20220406 ブラウザバックを無効化に伴い当URLは有効化するため
    //history.pushState({}, '', location.href);
	},

  head: {
      // link tags
      link: [
        { rel: 'canonical', href: 'https://www.ishiokashika.net/site/' }, //REP20220523
      ],
  },  

}
</script>