import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/Home.vue'
import Order from './components/Order.vue'
import Kyousei from './components/Kyousei.vue'
import Whitening from './components/Whitening.vue'
import Sinbi from './components/Sinbi.vue'
import Sisyu from './components/Sisyu.vue'
import Iin from './components/Iin.vue'
import Intyou from './components/Intyou.vue'
import Site from './components/Site.vue'
import Toiawase from './components/Toiawase.vue'
import Inplant from './components/Inplant.vue'
import Tiryourei from './components/Tiryourei.vue'
import Tiryousyousai from './components/Tiryousyousai.vue'
import Ireba from './components/Ireba.vue'
 
Vue.use(Router)


export default new Router({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
     // params: {id : 123}
      //props: true
    },
  
    {
      //path: '/order/:id',
      path: '/order',
      name: 'order',
      component: Order
    },
  
    {
      path: '/toiawase',
      //path: '/ryoukin',
      name: 'toiawase',
      component: Toiawase,
      //props: true
    },
    {
      path: '/site',
      name: 'site',
      component: Site
    },
 
    {
      //vuejs-pagenate対応時
      path: '/kyousei/:id',        
      //path: '/kyousei',
      name: 'kyousei',
      component: Kyousei
    },
    {
      //vuejs-pagenate対応時
      path: '/whitening/:id', 
      //path: '/whitening',
      name: 'whitening',
      component: Whitening
    },
 
    {
      //vuejs-pagenate対応時
      path: '/inplant/:id', 
      //path: '/inplant',
      name: 'inplant',
      component: Inplant
    },
   
    {
      //vuejs-pagenate対応時
      path: '/sisyu/:id', 
      //path: '/sisyu',
      name: 'sisyu',
      component: Sisyu
    },
  
    {
      //vuejs-pagenate対応時
      path: '/sinbi/:id',
      name: 'sinbi',
      component: Sinbi
    },
    
    {
      //path: '/tiryourei',

      //vuejs-pagenate対応時
      path: '/tiryourei/:id',  
      name: 'tiryourei',
      component: Tiryourei
    },    
   
    {
      path: '/iin',
      name: 'iin',
      component: Iin
    },    
    {
      path: '/intyou',
      //path: '/intyou/:id', //vuejs-pagenate対応時
      name: 'intyou',
      component: Intyou
    },    
  
    {
      path: '/tiryousyousai/:id',
      name: 'tiryousyousai',
      component: Tiryousyousai
    },
    {
      //vuejs-pagenate対応時
      path: '/ireba/:id',
      name: 'ireba',
      component: Ireba
    },
  ],

  /**  エラーなるので消した */
  /*
scrollBehavior (to) {
  // ハッシュがある時にはその地点へとスクロールする
  return to.hash ? { selector: to.hash, offset: { x: 0, y: 64 } } : { x: 0, y: 0 }
}
*/

/////////// 戻すときはこの/数だけ削除しろ
scrollBehavior (to,from,savedPosition) {
  //ブラウザ戻るボタンでの移動は以前の状態保持
  if (savedPosition) {

    //ブラウザバック検知アラートロジック１
    /* ここに入れると動いたページ回数だけalertが必要以上に出るのでダメ！！！
    history.replaceState(null, document.getElementsByTagName('title')[0].innerHTML, null);
    window.addEventListener('popstate', function() {
      alert('ブラウザバックを検知しました。');
      //window.location.reload();
      //this.window.location.href = 'http://www.yahoo.co.jp';
    });
    */

    //戻り位置gita test logic
    //sessionStorage.setItem('positionY', savedPosition.y);

    return savedPosition
    

  } else {

    //戻り位置gita test logic
    //sessionStorage.setItem('positionY', 0);
    //return { x: 0, y: 0 }
   
    
    //ハッシュある場合は指定要素位置へ戻る
    if (to.hash) {
      return {selector: to.hash}
      //さもなくば先頭位置へ戻る
    } else {
      return { x:0,y:0}
    }
   
  }
}

})