<template>
  <v-app>
    <header>
    
      <!--上部へッダー部分 -->
      <v-app-bar
        app
        light
        height="80"
      >
      
       <h1 class="siteName">{{titlefunc()}}</h1>
       <img class="denwa hidden-sm-and-down" src="@/assets2/top_tel.gif" height="52px" width="205px" alt="お問合せ">

        <!--ハンバーガー -->
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

        <!-- <a href="/"><img src="@/assets2/logosmall.gif" height="54px" width="265px" alt="石岡歯科医院"></a> -->
        <router-link v-bind:to="{ name: 'home' }"><img src="@/assets2/logosmall.gif" height="54px" width="265px" alt="石岡歯科医院"></router-link>
       
        <!--上部ナビゲーションメニュー -->
        <!--
        <v-tabs
          color="teal"
          slider-color="cyan lighten-2"
          slider-size="3"
        >
          <v-tab
            v-for="(menuItem, index) in menuItems"
            :key="index"
            :to="menuItem.url"
          >
            {{ menuItem.name }}
          </v-tab>
        </v-tabs>
        -->

      <!--上部ナビゲーションメニュー -->
      <!-- <v-toolbar-title>Vuetify</v-toolbar-title> -->
      <v-spacer></v-spacer>

      <!-- v-btnをv-toolbar-itemsタグで囲むことでボタンにマウスオーバーした際にv-app-barコンポーネントの高さ分の
      クリック領域を持たせることができる-->
      <v-toolbar-items>
      <v-btn v-show="$vuetify.breakpoint.mdAndUp" text :to="(menuItems.find((data) => data.name === 'HOME')).url">Home</v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{on}">
        <v-btn v-show="$vuetify.breakpoint.mdAndUp" v-on="on" text>治療内容<v-icon>mdi-arrow-down-drop-circle</v-icon></v-btn>
        </template>
        <v-list>
          <!-- <v-subheader>お困りですか？</v-subheader> -->
            <v-list-item
              v-for="(tiryouItem, index) in tiryouItems"
              :key="index"
              :to="tiryouItem.url"
            >
          <v-list-item-icon>
          <v-icon>{{ tiryouItem.icon }}</v-icon>
          </v-list-item-icon>  
          <v-list-item-content>
            <v-list-item-title style="word-break: break-all; white-space: normal;">{{ tiryouItem.name }}</v-list-item-title>
          </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>



      <v-menu offset-y>
        <template v-slot:activator="{on}">
        <v-btn v-show="$vuetify.breakpoint.mdAndUp" v-on="on" text>当院について<v-icon>mdi-arrow-down-drop-circle</v-icon></v-btn>
        </template>
        <v-list>
          <!-- <v-subheader>紹介します(^o^)</v-subheader> -->
            <v-list-item
              v-for="(touinItem, index) in touinItems"
              :key="index"
              :to="touinItem.url"
            >
          <v-list-item-icon>
          <v-icon>{{ touinItem.icon }}</v-icon>
          </v-list-item-icon>  
          <v-list-item-content>
            <v-list-item-title style="word-break: break-all; white-space: normal;">{{ touinItem.name }}</v-list-item-title>
          </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn v-show="$vuetify.breakpoint.mdAndUp" text :to="(menuItems.find((data) => data.name === 'ネット予約')).url">ネット予約</v-btn>

      <v-btn v-show="$vuetify.breakpoint.mdAndUp" text :to="(menuItems.find((data) => data.name === 'お問合せ')).url">お問合せ</v-btn>

      <v-btn v-show="$vuetify.breakpoint.mdAndUp" text :to="(menuItems.find((data) => data.name === 'SITEMAP')).url">SITEMAP</v-btn>

      </v-toolbar-items>

      <v-spacer></v-spacer>





      </v-app-bar>
   
      <!--ハンバーガーのサイドメニュー -->
      <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
        
      >
      <v-container>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              石岡歯科医院
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>



        <v-list
          nav
          dense
        >

        <v-list-item  :to="(menuItems.find((data) => data.name === 'HOME')).url">
          <v-list-item-title>HOME</v-list-item-title>
        </v-list-item>


          <v-list-group   
          no-action 
          > 
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>治療内容</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item               
              v-for="(tiryouItem, index) in tiryouItems"
              :key="index"
              :to="tiryouItem.url">
              <v-list-item-content>
                <v-list-item-title>{{ tiryouItem.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-group   
          no-action 
          > 
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>当院について</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item               
              v-for="(touinItem, index) in touinItems"
              :key="index"
              :to="touinItem.url">
              <v-list-item-content>
                <v-list-item-title>{{ touinItem.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          
        <v-list-item  :to="(menuItems.find((data) => data.name === 'ネット予約')).url">
          <v-list-item-title>ネット予約</v-list-item-title>
        </v-list-item>

        <v-list-item  :to="(menuItems.find((data) => data.name === 'お問合せ')).url">
          <v-list-item-title>お問合せ</v-list-item-title>
        </v-list-item>
        
        
        <v-list-item  :to="(menuItems.find((data) => data.name === 'SITEMAP')).url">
          <v-list-item-title>SITEMAP</v-list-item-title>
        </v-list-item>

        </v-list>
      </v-container>
      </v-navigation-drawer>
      <!--ハンバーガーのサイドメニュー終わり -->

    </header>
  </v-app>
</template>

<script>
import constants from '../common/constants'

export default {
  name :  'AppHeader',
  data () {
    return {
      drawer: false,
      menuItems: constants.menuItems,
      h1title: constants.h1title,
      tiryouItems: constants.tiryouItems,
      touinItems: constants.touinItems,
      //sitename: "川西市",
    }
  },
  
  
  methods:  {
      //右上H1にtitle入れる関数
      titlefunc: function() {
        //初期画面はqueryないので最初[0]の配列出す
        var out_title = this.h1title[0].title;

        for(var key in this.h1title) {
          if(this.h1title[key].name == this.$route.name ) {
            //console.debug(this.h1title[key].title);
            //console.debug(this.$route.name);
            out_title = this.h1title[key].title;
            //return this.h1title[key].title;
            break;
          }
        }
        return out_title ;
      }



  }

}
</script>

<style lang="scss" scoped>

/** @import "./src/styles/common/common.scss"; */



.v-toolbar__title {
  overflow: visible !important;
  margin-right: 50px !important;
}
 
.siteName {
  font: 10px Arial,sans-serif;
  text-align: right;
  position: absolute;
  top: 0px;
  left: auto;
  right: 5px;
  width: auto;
  padding: 0px 0px 0px 0px ;
  margin: 0px;
  color: #cccccc;  
 
  @include display_pc {
    font: bold 80% Arial,sans-serif;
    text-align: right;
    position: absolute;
    top: 0px;
    left: auto;
    right: 5px;
    width: auto;
    padding: 0px 0px 0px 0px ;
    margin: 0px;
    color: #cccccc;    
  }
}

.denwa {
  position: absolute;
  top: 0px;
  left: auto;
  right: 5px;
  width: auto;
  padding: 0px 0px 0px 0px ;
  margin: 0px;
  
 
  @include display_pc {
    position: absolute;
    top: 20px;
    left: auto;
    right: 5px;
    width: auto;
    padding: 0px 0px 0px 0px ;
    margin: 0px;
    
  }  

}

/**直接書いてもよい、上の@import不要
.v-app-bar__nav-icon {
  @media (min-width: 960px) {
    display: none !important;
  }
}
 
.v-tabs {
  display: none;
 
  @media (min-width: 960px) {
    display: block !important;
  }
}
**/

/** vue.configにグローバル指定エラー！！なので@importして使用する*/
/**エラー解明！vue.config.jsのprependDataが悪かった！！ */
/**additoinalDataや！@import不要 */
.v-app-bar__nav-icon {
  @include display_pc {
    display: none !important;
  }
}
/*
.v-tabs {
  display: none;
 
  @include display_pc {
    display: block !important;
  }
}
*/



</style>