<!--
<template>
  <v-footer
    class="blue"
  >
  <div class="footer">
   
    © {{ new Date().getFullYear() }} - 

          <router-link to="/" class="" style="color:white; text-decoration: none; ">
          <strong>伊丹市の歯医者さん＞石岡歯科医院</strong>
          </router-link>
          <div>TEL:072-770-0643</div>
          <div><a href="mailto:info@ishiokashika.net">mail:info@ishiokashika.net</a></div>
   
  </div>
  </v-footer>
</template>
-->

<template>
  <v-footer
    color="#4DD0E1"
    padless
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        v-for="(menuItem, index) in menuItems"
        :key="index"
        :to="menuItem.url"
        color="white"
        text
        rounded
        class="my-2"
      >
        {{ menuItem.name }}
      </v-btn>

      
      <v-btn
        v-for="(touinItem, index) in touinItems"
        :key="index"
        :to="touinItem.url"
        color="white"
        text
        rounded
        class="my-2"
      >
        {{ touinItem.name }}
      </v-btn>
      
      <v-btn
        v-for="(tiryouItem, index) in tiryouItems"
        :key="index"
        :to="tiryouItem.url"
        color="white"
        text
        rounded
        class="my-2"
      >
        {{ tiryouItem.name }}
      </v-btn>

      <v-col
        class="py-4 text-center white--text"
        style="background-color: #0097A7"
        cols="12"
      >
    © {{ new Date().getFullYear() }} - 

          <router-link to="/" class="" style="color:white; text-decoration: none; ">
          伊丹市の歯医者さん＞石岡歯科医院
          </router-link>
          <div>TEL:072-770-0643</div>
          <div><a href="mailto:info@ishiokashika.net">mail:info@ishiokashika.net</a></div>
   


      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import constants from '../common/constants'


  export default {

    data () {
      return {
        menuItems: constants.menuItems,
        h1title: constants.h1title,
        tiryouItems: constants.tiryouItems,
        touinItems: constants.touinItems,
        links: [
          'Home',
          'About Us',
          'Team',
          'Services',
          'Blog',
          'Contact Us',
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>
.footer {
    margin:auto;
    //margin-top: 15rem;
}
</style>