<!--(REP20220406 axios絶対パス指定に変更)  -->


<template>

  <section class="tiryousyousai">
    <AppBackgroundHolder :title="title"/>
  
    <v-container>
      <!-- パンくず -->
      <div>
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      </div>



      <h2 
      id="1"
      style="
        margin-bottom: 1rem;
        padding: 0.3em;
        background: #4DD0E1;
        border-left: solid 10px #0097A7;
        border-radius: 10px;
      "
      class="white--text text-md-h3 text-h4 font-weight-black"
      >治療詳細</h2>



      <h3
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >{{sinryou[0].name}}</h3>

      <h3
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >{{sinryou[0].date}}</h3>

      <h3
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >{{sinryou[0].naiyou}}</h3>
<!--
      <div>{{ Object.values(tiryouRei[0]) }} </div>
      <div>{{ tiryouRei[0]['name'] }} </div>
     -->


      <template>
        <v-container class="vsheet__toc">
          <v-row no-gutters>
            <v-col
            >
              <v-card
                class="pa-2"
                outlined
                tile
                elevation="3"
              >



                  <v-row class="d-flex child-flex">    
                    <!--  
                    <v-col cols="12" md="6"  v-for="list in tiryouRei" :key="list.index">
                      -->
                      

                  <!--  md="2"でLAPTOP以上は横6(= 2/12)画面ずつ出す -->
                  <!--
                    <v-col cols="12" md="6"  v-for="(newsItem, index) in 6"
                  :key="index"> 
                        <v-img
                          class="white--text align-end grey lighten-2"
                          src= '../assets2/D202108_3.jpg'
                          max-width="200"
                          max-height="150"
                          contain
                        >
                        </v-img>
                    </v-col>
                    -->

                    <v-col cols="12" md="6" v-if="sinryou[0]['gazou1_b'] != '' ">
                        <v-img
                          class="white--text align-end grey lighten-2"
                          :src="require('@/assets2/' + sinryou[0]['gazou1_b'])"
                          alt="治療詳細画像1"
                          max-width="200"
                          max-height="150"
                          contain
                        >
                        </v-img>
                    </v-col>
                    <v-col cols="12" md="6" v-if="sinryou[0]['gazou1_a'] != '' ">
                        <v-img
                          class="white--text align-end grey lighten-2"
                          :src="require('@/assets2/' + sinryou[0]['gazou1_a'])"
                          alt="治療詳細画像2"
                          max-width="200"
                          max-height="150"
                          contain
                        >
                        </v-img>
                    </v-col>
                    
                    <v-col cols="12" md="6" v-if="sinryou[0]['gazou2_b'] != '' ">
                        <v-img
                          class="white--text align-end grey lighten-2"
                          :src="require('@/assets2/' + sinryou[0]['gazou2_b'])"
                          alt="治療詳細画像3"
                          max-width="200"
                          max-height="150"
                          contain
                        >
                        </v-img>
                    </v-col>
              
                    <v-col cols="12" md="6" v-if="sinryou[0]['gazou2_a'] != '' ">
                        <v-img
                          class="white--text align-end grey lighten-2"
                          :src="require('@/assets2/' + sinryou[0]['gazou2_a'])"
                          alt="治療詳細画像4"
                          max-width="200"
                          max-height="150"
                          contain
                        >
                        </v-img>
                    </v-col>
                    <v-col cols="12" md="6" v-if="sinryou[0]['gazou3_b'] != '' ">
                        <v-img
                          class="white--text align-end grey lighten-2"
                          :src="require('@/assets2/' + sinryou[0]['gazou3_b'])"
                          alt="治療詳細画像5"
                          max-width="200"
                          max-height="150"
                          contain
                        >
                        </v-img>
                    </v-col>
                    <v-col cols="12" md="6" v-if="sinryou[0]['gazou3_a'] != '' ">
                        <v-img
                          class="white--text align-end grey lighten-2"
                          :src="require('@/assets2/' + sinryou[0]['gazou3_a'])"
                          alt="治療詳細画像6"
                          max-width="200"
                          max-height="150"
                          contain
                        >
                        </v-img>
                    </v-col>

                    
                  </v-row>  





              </v-card>
            </v-col> 
          </v-row>
        </v-container>
      </template>





      <!-- <input type="button"  class="btn" onclick="window.history.back();" value="  → history.back  "> -->

      <!-- <a @click="$router.back()">@click="$router.back()戻る</a> -->
      <!-- <router-link v-bind:to="{ path: 'inplant' , query: { page: '2' }}">戻れレれ</router-link> -->
      <!-- <button @click="$router.back()"> → 戻る </button> -->
      <v-btn
        large
        color="#0097A7"
        dark
        min-width="150"
        elevation="2"
  
        @click="$router.back()"
      >→ 戻る</v-btn>





      <!-- 
      <router-link
    v-if="$routerHistory.hasPrevious()"
    :to="{ path: $routerHistory.previous().path }">
    GO BACK
      </router-link>
      -->

     <!--  ↑JSで対応する
      <AppButton :button-text="buttonText" :url="url"/>

      <v-btn
        large
        color="#0097A7"
        dark
        min-width="150"
        elevation="2"
        style="window.history.back();"
      >
      <v-icon left>
        mdi-arrow-right-circle
      </v-icon>前のページに戻る
      </v-btn>
      -->
      
       

<!--
      <template>
        <v-list>
          <v-list-item v-for="list in displayLists" :key="list.index">
            <v-list-item-content>
              <v-list-item-title>{{ list.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="length"
            @input = "pageChange"
          ></v-pagination>
        </div>
      </template>
-->

<!--
      <template>
        <div class="count">  
          
          <table cellspacing="0" border="1px">  
            <tr>  
              <th>MYSQLテスト id</th>  
              <th>date</th>  
              <th>title</th>  
              <th>naiyou</th>  
            </tr>  
            <tr v-for="user in sinryou"
            :key="user.id"
            >  
              <td>{{user.id}}</td> 
              <td>{{user.date}}</td>  
              <td>{{user.title}}</td>  
              <td>{{user.naiyou}}</td>  
            
            </tr>  
          </table>  

        </div>  

      </template>

-->
    

    </v-container>

<go-top
  max-width="50"
  bg-color="#0097A7"
  :size="50"
></go-top>

  </section>

</template>
<script>
import AppBackgroundHolder from './AppBackgroundHolder.vue'
import GoTop from '@inotom/vue-go-top'

import axios from 'axios'
//import AppButton from './AppButton.vue'
//import AppButton from './AppButton.vue'
//import constants from '../common/constants'
//import VueRouterBackButton from 'vue-router-back-button'
 
export default {
  data () {
    return {
      page: 1,
      length:0,
      lists: [],
      displayLists: [],
      pageSize: 10,      

      sinryou: [],

      title: '治療詳細',

      buttonText: '治療例へ',
      url: '/tiryourei/12',
      //url: 'window.history.back();',
      //tiryouRei: constants.tiryouRei,
      tiryouRei: [],

      //backname: '',
      //backpath: '/inplant',
      //backpage: '2',
     

      //パンくず
      items: [
        {
          text: 'Home',
          disabled: false,
          //href: '../',
          to: '/',
        },
        {
          text: '治療詳細',
          disabled: true,
          href: './tiryousyousai',
        },
      ],      
    }
  },

  //  コンポーネントに指定時や！/////////
  beforeRouteEnter : function (to, from, next) {
    //console.log('component:beforeRouteEnter');
    //console.log(this.$route.fullPath);
    //console.log(to.$route.fullPath);
    //console.log(from.params.id);
    console.log(from.fullPath);
    console.log(from.path);
    //console.log(from.name);
    console.log(from.query.page);
    //this.backname = from.name;
    //this.backpath = from.path;
    //this.bakpage= from.query.page;
    //this.updateItem();
    next();
  },

  //////////////////////////////////////  
  

  methods: {
    pageChange: function(pageNumber){
      this.displayLists = this.tiryouRei.slice(this.pageSize*(pageNumber -1), this.pageSize*(pageNumber));
    },
  },

  created() {
      //axios.get('functions/database.php').then((response) => {   
      axios.get('/functions/database.php',{  //REP20220406
        params: {
          id: this.$route.params.id
        }
      }).then((response) => {   
      // console.log(data); // works fine
      this.sinryou = response.data
      //this.tiryouRei = this.sinryou

      /*
      console.log('success')
      console.log(response.data)
      console.log(this.sinryou[0].name)
      console.log(this.sinryou[0]['name'])
      console.log(this.sinryou[1].name)
      console.log(this.sinryou[1]['name'])
      console.log(response.data['name'])
      console.log(response.data.name)
      console.log('end')
      */
    });
  },


  components: {
    AppBackgroundHolder,
    GoTop,
    //AppButton,
    //VueRouterBackButton,
    //AppButton,
  },

  mounted: function(){
		document.title = "伊丹市の歯医者 石岡歯科医院の治療詳細";
		document.querySelector("meta[name='description']").setAttribute('content', "治療詳細 - 伊丹市の歯医者石岡歯科医院");

    this.length = Math.ceil(this.tiryouRei.length/this.pageSize);
    this.displayLists = this.tiryouRei.slice(this.pageSize*(this.page -1), this.pageSize*(this.page));
/*
    this.lists = new Array(99).fill().map((v,i)=> {
      return { id : i,title : 'Title' + i };
    });
    this.length = Math.ceil(this.lists.length/this.pageSize);
    this.displayLists = this.lists.slice(this.pageSize*(this.page -1), this.pageSize*(this.page));
*/

    // ADD20220406 ブラウザバックを無効化に伴い当URLは有効化するため
    //history.pushState({}, '', location.href);

	},

  head: {
      // link tags
      link: [
        { rel: 'canonical', href: 'https://www.ishiokashika.net/tiryousyousai/1' },  //REP20220406
      ],
  },  

}
</script>
<style lang="scss" scoped>

.vsheet__toc {
  width: 550px;
  margin-left:auto;

  color:#616161; //textはgray-darken2
  padding: 20px 20px 20px 20px;
  margin-bottom: 20px;
  
  p {font-size: 24px;text-align: center;}

  a {text-decoration: none !important;color: #616161; }
  a:hover {text-decoration: underline !important;}
  li {padding-top:10px; list-style-type: none !important;}

}


.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  
  background-color: #0097A7;
  --padding-x: 1.2em;
  border-color: transparent;
  border-radius: 0.25em;
  box-shadow: 0 1px 4px hsla(220, 90%, 37%, 0.25);
  line-height: 3.0;
  
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  transition: .2s;
  
  &:hover {
    background-color: #0097A7;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 2px hsla(220, 90%, 56%, 0.2);
  }
  
  &:active {
    transform: translateY(2px);
  }
}

</style>