

<template>

  <section class="intyou">
    <AppBackgroundHolder :title="title"/>
  
    <v-container>
      <!-- パンくず -->


      <h2 
      id="1"
      style="
        margin-bottom: 1rem;
        padding: 0.3em;
        background: #4DD0E1;
        border-left: solid 10px #0097A7;
        border-radius: 10px;
      "
      class="white--text text-md-h3 text-h4 font-weight-black"
      >院長紹介</h2>
      <h3
      id="1-1"
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >プロフィール</h3>

      <template>
        <v-container class="grey lighten-5">
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
                <v-img
                  class="white--text align-end"
                  :src="require('@/assets2/top_intyou.jpg')"
                  alt="院長写真"
                  height="111"
                  width="131"
                >
                </v-img>
                <v-card-text class="text--primary">
                  <ul>
                    <li>豊中市出身。</li>
                    <li>福岡歯科大学卒業後、同大学の矯正研究室に４年間在籍。</li>
                    <li>昭和６０年兵庫県伊丹市（白ゆり幼稚園前）で開業。</li>
                  </ul>   
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >

                <v-card-text class="text--primary">
                  <ul>
                    <li>所属団体
                      <ul>
                        <li>日本インプラント学会所属</li>
                        <li>全日本矯正歯科学会所属</li>
                        <li>伊丹市歯科医師会所属</li>
                        <li>白ゆり幼稚園園医</li>
                      </ul>          
                    </li>

                    <li>趣味
                      <ul>
                        <li>読書</li>
                        <li>スキー</li>
                        <li>マリンスポーツ</li>
                      </ul>          
                    </li>

                  </ul>
                </v-card-text>
              </v-card>
            </v-col>            
          </v-row>
        </v-container>
       

      </template>

      <h3
      id="1-1"
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >学会発表・マスコミ掲載の一例</h3>

      <template>
        <v-container class="grey lighten-5">
          <v-row no-gutters>

            <v-col

            >
              <v-card
                class="pa-2"
                outlined
                tile
              >

                <v-card-text class="text--primary">
                  <ul>
                    <li>昭和60年10月 西日本矯正学会にて「両側性転移歯矯正治療」について発表</li>
                    <li>昭和61年01月 西日本矯正学会誌に上記論文掲載</li>
                    <li>平成04年11月 大阪インプラント研究会にて「GTR法(歯周組織再生誘導法)」について発表</li>
                    <li>平成05年03月 大阪インプラント研究会の学会誌に上記論文掲載</li>
                    <li>平成05年11月 インプラントGBR法(骨再生術)について発表</li>
                    <li>平成14年06月 夕刊フジにて虫歯予防デーに伴い取材を受け掲載</li>
                  </ul>  
                </v-card-text>
              </v-card>
            </v-col>            
          </v-row>
        </v-container>
      </template>


      <h3
      id="1-1"
      style="
        border-left: solid 10px  #0097A7;
        border-radius: 10px;
      "
      class="grey--text text--darken-2 text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
      >患者様へのメッセージ等</h3>

      <template>
        <v-container class="grey lighten-5">
          <v-row no-gutters>

            <v-col

            >
              <v-card
                class="pa-2"
                outlined
                tile
              >

                <v-card-text class="text--primary">
                  <ul>
                    <li>まず、むし歯や歯周病にならないよう普段の生活で気をつける事です。気をつける事は普段からの歯磨きを正しくきちんと行い、歯石をためないようにすることです。歯石を定期的に取ることで歯周病もある程度予防できます。そしてなんといっても規則正しい食生活を行い、過労を防ぎ体の免疫力を高める事が大事です。</li>
                    <li>一般歯科と矯正歯科が専門家している現状で当医院では両科を併設し院長自ら診察させて頂きますので患者様にとってもトータルに診てもらえるという利点がございます。インプラント、矯正治療については現在の様に普及する前から力を入れ研究しておりましたので是非技術的にも安心な当医院におまかせください。</li>
                    <li>審美治療にも力を入れております。審美治療は、単に歯を白くしたり歯並びを治すだけではなく、見えない部分ではありますが根管治療（根の消毒）や歯周病治療を行い、いかに健康な歯と歯ぐきを保つようにするかが大事です。当医院ではホワイトニング、ラミネートベニアなど見かけだけではなく機能性と歯牙を保存する治療にも力を入れ常に向上に努めております。</li>
                  </ul>  
                </v-card-text>
              </v-card>
            </v-col>            
          </v-row>
        </v-container>



      

      </template>      


    </v-container>

<go-top
  max-width="50"
  bg-color="#0097A7"
  :size="50"
></go-top>

  </section>

</template>

<script>
import AppBackgroundHolder from './AppBackgroundHolder.vue'
import GoTop from '@inotom/vue-go-top'

//import Paginate from 'vuejs-paginate'
//Vue.component('paginate', Paginate)
 
export default {
   //el: '#app',
   data () {
    return {
     
      items : [],
      title: '院長紹介',
  
    }
  },

  components: {
    AppBackgroundHolder,
    GoTop,
    //Paginate,
  },

  mounted: function(){
		document.title = "伊丹市の歯医者 院長紹介";
		document.querySelector("meta[name='description']").setAttribute('content', "院長紹介 - 伊丹市の歯医者石岡歯科医院");

    // ADD20220406 ブラウザバックを無効化に伴い当URLは有効化するため
    //history.pushState({}, '', location.href);
	},

  head: {
      // link tags
      link: [
        { rel: 'canonical', href: 'https://www.ishiokashika.net/intyou/' },  //REP20220523
      ],
  },  

}
</script>
<style lang="scss" scoped>

</style>