<template>
  <v-row class="button">
    <v-col>
      <v-btn
        large
        color="#0097A7"
        dark
        min-width="150"
        elevation="2"
        :to="url"
      >
      <v-icon left>
        mdi-arrow-right-circle
      </v-icon>{{buttonText}}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    buttonText: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="scss">
.button {
  text-align: center;
}
</style>